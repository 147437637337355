import React, { useEffect, useRef, useState } from "react";
import NewStack from "./newStack";
import NewDomain from "./newDomain";
import NewProj from "./newProj";
import { useStacks } from "../../../hooks/useTechs";
import { CircularProgress } from "@mui/material";
import SpDialog from "../../../globals/SpDialog";
import {
  getFirestore,
  arrayRemove,
  arrayUnion,
  deleteField,
} from "firebase/firestore";

const AddNew = (props) => {
  const storage = props.firebase.getStorage();
  // const catRef = useRef(props.categoryId);

  const [techData, setTechData] = useState({
    techCategory: {},
    name: "",
    searchKeywords: "",
    rank: 0,
    subcategory: "",
    show: false,
    description: "",
    logoStorage: "",
    assessments: [],
  });
  const [newData, setNewData] = useState({
    category: {},
    name: "",
    searchKeywords: "",
    show: false,
    description: "",
    assessments: [],
  });
  const [logo, setLogo] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedAssessments, setSelectedAssessments] = useState([]);

  const resetStates = (callback) => {
    setTechData({
      techCategory: {},
      name: "",
      searchKeywords: "",
      rank: 0,
      subcategory: "",
      show: false,
      description: "",
      logoStorage: "",
      assessments: [],
    });
    setNewData({
      category: {},
      name: "",
      searchKeywords: "",
      show: false,
      description: "",
      assessments: [],
    });
    setLogo({});
    setSelectedAssessments([]);

    props.setOpenDialog(false);
    props.setIsEdit(false);

    // Execute callback after state reset
    setTimeout(() => {
      if (callback) callback();
    }, 300);
  };

  const showOptions = [
    { value: true, label: "true" },
    { value: false, label: "false" },
  ];

  useEffect(() => {
    if (!props.open) {
      // Reset all states when dialog closes
      setTechData({
        techCategory: {},
        name: "",
        searchKeywords: "",
        rank: 0,
        subcategory: "",
        show: false,
        description: "",
        logoStorage: "",
        assessments: [],
      });
      setNewData({
        category: {},
        name: "",
        searchKeywords: "",
        show: false,
        description: "",
        assessments: [],
      });
      setLogo({});
      setSelectedAssessments([]);
      setLoading(false);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.isEdit && props.open) {
      // Only set data when dialog is open
      const { assessments, ...data } = props.defaultData;
      setSelectedAssessments(assessments ?? []);
      if (props.collection === "techStacks150r") {
        setTechData({ ...data });
      } else {
        setNewData({ ...data });
      }
    }
  }, [props.isEdit, props.defaultData?.id, props.collection, props.open]);

  const selectLogo = (e) => {
    const file = e.target.files[0];
    if (file?.type?.includes("image")) {
      setLogo({ file: file, url: URL.createObjectURL(file) });
    }
  };

  const uploadLogo = async (file, name) => {
    if (file) {
      let fileExt = file.type.replace("image/", "");
      let url;
      switch (name) {
        case "techStacks150r":
          const ref = await storage
            .ref(`techStacks/${techData.name}.${fileExt}`)
            .put(file);
          url = await ref.ref.getDownloadURL();
          return url;

        case "sectors":
          const domRef = await storage
            .ref(`sectors/${newData.name}.${fileExt}`)
            .put(file);
          url = await domRef.ref.getDownloadURL();
          return [url, `${newData.name}.${fileExt}`];

        case "subSectors":
          const subRef = await storage
            .ref(
              `sectors/${props.selectedSector.name}/${newData.name}.${fileExt}`
            )
            .put(file);
          url = await subRef.ref.getDownloadURL();
          return [url, `${newData.name}.${fileExt}`];

        case "categories":
          const projRef = await storage
            .ref(`projectCategories/${newData.name}.png`)
            .put(file);
          url = await projRef.ref.getDownloadURL();
          return [url, `${newData.name}`];

        default:
          break;
      }
    }
  };

  const addNewData = async (name) => {
    const { techCategory, ...stackData } = techData;
    const { category, ...others } = newData;
    let techId = techCategory?.value ?? category?.value;

    let data = name === "techStacks150r" ? stackData : others;
    data["addedOn"] = new Date();
    if (data.name) {
      setLoading(true);
      try {
        const url = await uploadLogo(logo.file, props.collection);
        if (url) {
          if (name !== "techStacks150r") {
            data["logoStorage"] = url[0];
          } else {
            data["logoStorage"] = url;
            data["title"] = data.name;
          }

          if (name === "sectors" || name === "subSectors") {
            data["image"] = url[1];
          }
          if (name === "categories") {
            data["iconImage"] = url[1];
          }

          let docRef;
          if (name === "subSectors") {
            docRef = await props.firebase.createDocumentFromPath(
              `${props.selectedSector.ref.path}/subSectors`,
              data
            );
          } else {
            docRef = await props.firebase.addOrEditTechs(
              props.collection,
              techId,
              data
            );
          }
          // console.log("docRef:", docRef);
          return docRef;
          // Make sure we have a proper document reference
          // if (docRef?.id) {
          //   return props.firebase.db.collection(name).doc(docRef.id);
          // } else if (typeof docRef === "string") {
          //   // If we got back a document ID
          //   return props.firebase.db.collection(name).doc(docRef);
          // }
          // throw new Error("Invalid document reference returned");
        }
      } catch (error) {
        console.error("Error in addNewData:", error);
        throw error;
      }
    }
    return null;
  };

  const editData = async (name) => {
    let theData = techData.name ? techData : newData;
    const {
      catIndex,
      techCategory,
      category,
      popularQueries,
      nameIndex,
      stats,
      catData,
      catId,
      owner,
      ref,
      ...data
    } = theData;

    if (!logo.file) {
      setLoading(true);
      await ref.update(data);
      setLoading(false);
    } else {
      setLoading(true);
      const url = await uploadLogo(logo.file, props.collection);
      if (url) {
        data["logoStorage"] = name !== "techStacks150r" ? url[0] : url;
        if (name === "techStacks150r") data["title"] = data.name;
        if (name === "sectors" || name === "subSectors") data["image"] = url[1];
        if (name === "categories") data["iconImage"] = url[1];

        await ref.update(data);
        setLoading(false);
      }
    }
    return ref;
  };

  const onSave = async (name) => {
    if (loading) return;

    try {
      setLoading(true);
      props?.setAssessments(selectedAssessments);
      const assessmentsForSave = selectedAssessments.map(({ ref, rank }) => ({
        ref,
        rank,
      }));

      const db = props.firebase.db;

      if (!props.isEdit) {
        if (logo.file) {
          let data = name === "techStacks150r" ? techData : newData;
          data.assessments = assessmentsForSave;

          // Save to original collection and get the new reference
          const newRef = await addNewData(name);
          if (!newRef) {
            throw new Error("Failed to create new document");
          }

          // Create a batch for all updates including the initial document
          const batch = db.batch();

          // Update the document with assessments
          batch.update(newRef, {
            assessments: assessmentsForSave,
          });

          // Prepare all catalog updates
          selectedAssessments.forEach((assessment) => {
            batch.update(assessment.ref, {
              skills: arrayUnion({
                ref: newRef,
                rank: assessment.rank,
              }),
            });
          });

          // Execute all updates atomically
          await batch.commit();

          props.refreshData();
          resetStates(() => setLoading(false)); // Pass setLoading as callback
        }
      } else {
        let data = name === "techStacks150r" ? techData : newData;
        data.assessments = assessmentsForSave;

        try {
          // First update the document and handle logo if needed
          const docRef = await editData(name);

          // Then handle the assessment references in a transaction
          await db.runTransaction(async (transaction) => {
            // READS FIRST
            // 1. Get the document snapshot
            const docSnapshot = await transaction.get(docRef);
            const previousAssessments = docSnapshot.data().assessments || [];

            // Find removed assessments first
            const removedAssessments = previousAssessments.filter(
              (prevAssessment) =>
                !selectedAssessments.some(
                  (current) => current.ref.path === prevAssessment.ref.path
                )
            );

            // 2. Get all catalog documents we need to update
            const catalogDocs = await Promise.all([
              ...removedAssessments.map((assessment) =>
                transaction.get(assessment.ref)
              ),
              ...selectedAssessments.map((assessment) =>
                transaction.get(assessment.ref)
              ),
            ]);

            // Prepare the updates
            const updates = [];

            // THEN WRITES
            // 1. Update the main document
            updates.push(() =>
              transaction.update(docRef, {
                assessments: assessmentsForSave,
              })
            );

            // 2. Handle removed assessments
            removedAssessments.forEach((assessment, index) => {
              const catalogDoc = catalogDocs[index];
              const catalogData = catalogDoc.data();

              if (catalogData.skills) {
                const updatedSkills = catalogData.skills.filter(
                  (skill) => skill.ref.path !== docRef.path
                );

                updates.push(() =>
                  transaction.update(assessment.ref, {
                    skills:
                      updatedSkills.length > 0 ? updatedSkills : deleteField(),
                  })
                );
              }
            });

            // 3. Handle selected assessments
            selectedAssessments.forEach((assessment, index) => {
              const catalogDoc = catalogDocs[removedAssessments.length + index];
              const catalogData = catalogDoc.data();

              let currentSkills = catalogData.skills || [];
              currentSkills = currentSkills.filter(
                (skill) => skill.ref.path !== docRef.path
              );
              currentSkills.push({
                ref: docRef,
                rank: assessment.rank,
              });

              updates.push(() =>
                transaction.update(assessment.ref, {
                  skills: currentSkills,
                })
              );
            });

            // Execute all updates
            updates.forEach((update) => update());
          });

          props.refreshData();
          resetStates(() => setLoading(false)); // Pass setLoading as callback
        } catch (error) {
          console.error("Error updating data:", error);
        }
      }
    } catch (error) {
      console.error("Error in onSave:", error);
      setLoading(false); // Only set loading false immediately on error
    }
  };

  const toggleAssessment = (assessment) => {
    // console.log("toggleAssessment called with assessment:", assessment);

    setSelectedAssessments((prev) => {
      const exists = prev.some((item) => item.ref.path === assessment.ref.path);

      if (exists) {
        // Remove assessment and reassign ranks
        const updated = prev
          .filter((item) => item.ref.path !== assessment.ref.path)
          .map((item, index) => ({ ...item, rank: index + 1 }));
        // console.log("Updated selectedAssessments after removal:", updated);
        return updated;
      } else {
        // Add assessment with new rank
        const updated = [...prev, { ...assessment, rank: prev.length + 1 }];
        // console.log("Updated selectedAssessments after addition:", updated);
        return updated;
      }
    });
  };

  const onRemoveAssessment = (assessment) => {
    // console.log("onRemoveAssessment called with assessment:", assessment);

    setSelectedAssessments((prev) => {
      const updated = prev
        .filter((item) => item.ref.path !== assessment.ref.path)
        .map((item, index) => ({ ...item, rank: index + 1 }));
      // console.log("Updated selectedAssessments after removal:", updated);
      return updated;
    });
  };

  // console.log({
  //   techData,
  //   newData,
  // });

  const getNew = () => {
    switch (props.collection) {
      case "techStacks150r":
        return (
          <NewStack
            techsCategories={props.techsCategories}
            setTechData={setTechData}
            techData={techData}
            handleOpen={props.handleFileUpload}
            selectLogo={selectLogo}
            logo={logo}
            setLogo={setLogo}
            openDialog={props.openDialog}
            isEdit={props.isEdit}
            editData={props.defaultData}
            show={showOptions}
            defaultData={props.defaultData}
            searchAssessments={props.searchAssessments}
            searchData={props.searchData}
            setSearchData={props.setSearchData}
            showDropdown={props.showDropdown}
            setShowDropdown={props.setShowDropdown}
            selectedAssessmentNames={props.selectedAssessmentNames}
            onToggleAssessment={props.onToggleAssessment}
            selectedAssessments={selectedAssessments}
            toggleAssessment={toggleAssessment}
            onRemoveAssessment={onRemoveAssessment}
          />
        );

      case "categories":
        return (
          <NewProj
            techsCategories={props.techsCategories}
            setNewData={setNewData}
            newData={newData}
            handleOpen={props.handleFileUpload}
            selectLogo={selectLogo}
            logo={logo}
            setLogo={setLogo}
            openDialog={props.openDialog}
            isEdit={props.isEdit}
            editData={props.defaultData}
            show={showOptions}
            onToggleAssessment={props.onToggleAssessment}
            searchAssessments={props.searchAssessments}
            searchData={props.searchData}
            setSearchData={props.setSearchData}
            showDropdown={props.showDropdown}
            setShowDropdown={props.setShowDropdown}
            selectedAssessmentNames={props.selectedAssessmentNames}
            toggleAssessment={toggleAssessment}
            onRemoveAssessment={onRemoveAssessment}
            selectedAssessments={selectedAssessments}
          />
        );

      default:
        return (
          <NewDomain
            techsCategories={props.techsCategories}
            setNewData={setNewData}
            newData={newData}
            handleOpen={props.handleFileUpload}
            selectLogo={selectLogo}
            logo={logo}
            setLogo={setLogo}
            openDialog={props.openDialog}
            isEdit={props.isEdit}
            editData={props.defaultData}
            show={showOptions}
            isSub={props?.isSub ?? false}
            onToggleAssessment={props.onToggleAssessment}
            searchAssessments={props.searchAssessments}
            searchData={props.searchData}
            setSearchData={props.setSearchData}
            showDropdown={props.showDropdown}
            setShowDropdown={props.setShowDropdown}
            selectedAssessmentNames={props.selectedAssessmentNames}
            toggleAssessment={toggleAssessment}
            onRemoveAssessment={onRemoveAssessment}
            selectedAssessments={selectedAssessments}
          />
        );
    }
  };

  return (
    <SpDialog
      open={props?.open}
      locked={true}
      onClose={props?.onClose}
      useCustomBox={true}
    >
      <div
        className="collectionModal column modal-content"
        id="col_data-request_form"
      >
        {getNew()}
        <div className="request_form-action row">
          <button
            className="spbtn spbtn-sub"
            onClick={() => onSave(props.collection)}
            disabled={loading}
          >
            {loading ? (
              <div className="button-content">
                <CircularProgress size={20} color="inherit" />
                <span style={{ marginLeft: "8px" }}>Saving...</span>
              </div>
            ) : (
              "Save"
            )}
          </button>
          <button
            className="spbtn spbtn-canc"
            onClick={() => resetStates(() => {})}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    </SpDialog>
  );
};

export default AddNew;
