import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { db, fire, spApp } from "../globals/services";
import { useStableArray } from "./useStableArray";

function isDocumentPath(path) {
  const segments = path?.split("/")?.filter(Boolean);
  return segments.length % 2 === 0; // even segments => doc
}

// Helper to fetch video URLs
async function fetchVideoURL({ devID, videoPath }) {
  const storage = spApp.storage();
  const videoRef = storage.ref(
    `devs/${devID}/assessments/${videoPath.course}/${videoPath.unit}/${videoPath.question}`
  );

  try {
    return await videoRef.getDownloadURL();
  } catch (error) {
    console.error("Failed to fetch video URL", error);
    return null;
  }
}

// Fetch a single question's data, including submissions and video URL
async function fetchQuestionData({ devID, questionRef }) {
  try {
    const questionSnapshot = await questionRef.get();
    const questionData = questionSnapshot.data();
    const videoURL = questionData.videoPath
      ? await fetchVideoURL({ devID, videoPath: questionData.videoPath })
      : null;

    const submissionsSnapshot = await questionSnapshot.ref
      .collection("submissions")
      .get();
    const submissionsData = submissionsSnapshot.docs.map((submission) => ({
      id: submission.id,
      ...submission.data(),
      ref: submission.ref,
    }));

    return {
      id: questionSnapshot.id,
      ...questionData,
      videoURL,
      submissionsData,
    };
  } catch (error) {
    console.error("Error fetching question data", error);
    return null;
  }
}

export async function fetchQuestionsForUnit({ devID, unitRef }) {
  try {
    const unitSnapshot = await unitRef.get();
    const { questions, ...unitData } = unitSnapshot.data() || {};

    if (!questions) {
      console.log("No questions available for this unit.");
      return { id: unitSnapshot.id, ...unitData, questionsData: [] };
    }

    const questionsData = await Promise.all(
      questions.map((questionRef) => fetchQuestionData({ devID, questionRef }))
    ).then((results) => results.filter(Boolean));

    return { id: unitSnapshot.id, ...unitData, questionsData };
  } catch (error) {
    console.error("Error fetching questions for unit", error);
    return null;
  }
}

// Fetch a single unit's data, including questions
async function fetchUnitData({ devID, unitRef, setRefetch }) {
  const unitSnapshot = await unitRef.get();
  const { questions, ...unitData } = unitSnapshot.data() || {};

  if (!questions) {
    console.log("No questions available yet, triggering refetch...");
    if (setRefetch) setRefetch(true);
    return { id: unitSnapshot.id, ...unitData, questionsData: [] };
  }

  const questionsData = await Promise.all(
    questions.map((question) =>
      question ? fetchQuestionData({ devID, questionRef: question }) : null
    )
  ).then((results) => results.filter(Boolean));

  return { id: unitSnapshot.id, ...unitData, questionsData };
}

// Given an array of assessments {source, units}, fetch their full data
async function fetchAssessmentsArray({ devID, assessmentsArray, setRefetch }) {
  return Promise.all(
    assessmentsArray.map(async (assessment) => {
      const { source, units, docId, ...rest } = assessment;
      const sourceDoc = await source.get();
      const sourceData = {
        ...sourceDoc.data(),
        id: sourceDoc.id,
        ref: sourceDoc.ref,
      };

      const unitsData = await Promise.all(
        units.map((unitRef) => fetchUnitData({ devID, unitRef, setRefetch }))
      );

      // Return the docId along with the fetched data
      return { ...rest, sourceData, unitsData, docId, devID };
    })
  );
}

export function useGenericAssessmentsForPath({
  devID,
  path,
  assessmentFieldKey = "assessments",
}) {
  const [assessments, setAssessments] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const docOrCollectionIsDoc = useMemo(() => isDocumentPath(path), [path]);

  const doFetch = useCallback(async () => {
    if (!path) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      let assessmentsArray = [];

      if (docOrCollectionIsDoc) {
        // doc path
        const docSnap = await db.doc(path).get();
        if (docSnap.exists) {
          const data = docSnap.data();
          // console.log("datasnap", data);
          const docAssessments = data?.[assessmentFieldKey] || [];
          // Attach docId to each assessment
          assessmentsArray = docAssessments.map((ass) => ({
            ...ass,
            docId:
              data?.techRef?.id ??
              data?.projectRef?.id ??
              data?.domainRef?.id ??
              docSnap?.id,
            ref: docSnap?.ref,
            assessmentScore: data?.assessmentScore ?? 0,
          }));
        }
      } else {
        // collection path
        const collectionSnap = await db.collection(path).get();
        collectionSnap.forEach((doc) => {
          const docData = doc.data();
          // console.log({ path, assessmentScore: docData?.assessmentScore });
          const docAssessments = docData?.[assessmentFieldKey] || [];
          // Attach docId to each assessment
          const withDocId = docAssessments.map((ass) => ({
            ...ass,
            docId:
              docData?.techRef?.id ??
              docData?.projectRef?.id ??
              docData?.domainRef?.id ??
              doc?.id,
            ref: doc?.ref,
            assessmentScore: docData?.assessmentScore ?? 0,
          }));
          assessmentsArray.push(...withDocId);
        });
      }

      const fetched = await fetchAssessmentsArray({
        devID,
        assessmentsArray,
        setRefetch: (val) => val && setTriggerRefetch(true),
      });

      // Group by docId instead of sourceData.id
      const groupedByDocId = fetched.reduce((acc, item) => {
        const dId = item.docId;
        if (!acc[dId]) acc[dId] = [];
        acc[dId].push(item);
        return acc;
      }, {});

      setAssessments(groupedByDocId);
      setLoading(false);
    } catch (err) {
      console.error(`Error fetching assessments for path: ${path}`, err);
      setError(err);
      setLoading(false);
    }
  }, [path, assessmentFieldKey, devID, docOrCollectionIsDoc]);

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  useEffect(() => {
    if (triggerRefetch) {
      const timer = setTimeout(() => {
        doFetch();
        setTriggerRefetch(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [triggerRefetch, doFetch]);

  const refetch = useCallback(() => setTriggerRefetch(true), []);

  return {
    assessments,
    loading,
    error,
    refetch,
  };
}

export function fetchAssessmentsForPath({
  devID,
  path,
  assessmentFieldKey = "assessments",
  onUpdate,
  onError,
}) {
  if (!path) return null;

  const docOrCollectionIsDoc = isDocumentPath(path);

  if (docOrCollectionIsDoc) {
    // Listen to a single document
    const unsubscribe = db.doc(path).onSnapshot(
      async (docSnap) => {
        if (docSnap.exists) {
          const data = docSnap.data();
          const docAssessments = data?.[assessmentFieldKey] || [];
          const assessmentsArray = docAssessments.map((ass) => ({
            ...ass,
            docId: docSnap.id,
            ref: docSnap.ref,
            assessmentScore: data?.assessmentScore ?? 0,
          }));

          const fetched = await fetchAssessmentsArray({
            devID,
            assessmentsArray,
          });
          onUpdate({ [docSnap.id]: fetched });
        } else {
          onUpdate({});
        }
      },
      (error) => {
        console.error(`Snapshot error for path: ${path}`, error);
        onError && onError(error);
      }
    );

    return unsubscribe;
  } else {
    // Listen to a collection
    const unsubscribe = db.collection(path).onSnapshot(
      async (collectionSnap) => {
        const assessmentsArray = [];

        collectionSnap.forEach((doc) => {
          const data = doc.data();
          const docAssessments = data?.[assessmentFieldKey] || [];
          const withDocId = docAssessments.map((ass) => ({
            ...ass,
            docId: doc.id,
            ref: doc.ref,
            assessmentScore: data?.assessmentScore ?? 0,
          }));
          assessmentsArray.push(...withDocId);
        });

        const fetched = await fetchAssessmentsArray({
          devID,
          assessmentsArray,
        });
        const groupedByDocId = fetched.reduce((acc, item) => {
          if (!acc[item.docId]) acc[item.docId] = [];
          acc[item.docId].push(item);
          return acc;
        }, {});

        onUpdate(groupedByDocId);
      },
      (error) => {
        console.error(`Snapshot error for path: ${path}`, error);
        onError && onError(error);
      }
    );

    return unsubscribe;
  }
}

/**
 * useAllAssessments
 *
 * Fetches assessments from multiple full paths (which could be doc or collection paths).
 * Returns an object keyed by the given paths, each containing an array of { sourceData, unitsData }.
 *
 * @param {Object} params
 * @param {string} params.devID - The developer's ID (used for video URLs)
 * @param {Array<string>} params.paths - An array of full paths to docs or collections.
 * @param {Object} [params.assessmentFieldKeys={}] - Map of path => assessment field key.
 *
 * @returns {{ allAssessments: Object, loading: boolean, error: Error|null, refetchAll: Function }}
 */
export function useAllAssessments({
  devID,
  paths = [],
  assessmentFieldKeys = {},
}) {
  const [allAssessments, setAllAssessments] = useState({});
  const [loadingByPath, setLoadingByPath] = useState({});
  const [errorByPath, setErrorByPath] = useState({});
  const [unsubscribers, setUnsubscribers] = useState([]);

  // Function to fetch assessments for a specific path
  const fetchDataForPath = useCallback(
    (path) => {
      setLoadingByPath((prev) => ({ ...prev, [path]: true }));
      setErrorByPath((prev) => ({ ...prev, [path]: null }));

      // Call fetchAssessmentsForPath with a callback for real-time updates
      const unsubscribe = fetchAssessmentsForPath({
        devID,
        path,
        assessmentFieldKey: assessmentFieldKeys[path] || "assessments",
        onUpdate: (data) => {
          setAllAssessments((prev) => ({ ...prev, [path]: data }));
          setLoadingByPath((prev) => ({ ...prev, [path]: false }));
        },
        onError: (error) => {
          setErrorByPath((prev) => ({ ...prev, [path]: error }));
          setLoadingByPath((prev) => ({ ...prev, [path]: false }));
        },
      });

      return unsubscribe;
    },
    [devID, assessmentFieldKeys]
  );

  // Fetch data for all paths on mount and when paths change
  useEffect(() => {
    const newUnsubscribers = paths.map((path) => fetchDataForPath(path));
    setUnsubscribers(newUnsubscribers);

    // Cleanup function to unsubscribe from all listeners
    return () => {
      newUnsubscribers.forEach((unsub) => unsub && unsub());
    };
  }, [fetchDataForPath, paths]);

  // Function to refetch data for a specific path
  const refetchByPath = useCallback(
    (path) => {
      unsubscribers.forEach((unsub) => unsub && unsub());
      const newUnsubscribe = fetchDataForPath(path);
      setUnsubscribers([newUnsubscribe]);
    },
    [fetchDataForPath, unsubscribers]
  );

  // Function to refetch data for all paths
  const refetchAll = useCallback(() => {
    unsubscribers.forEach((unsub) => unsub && unsub());
    const newUnsubscribers = paths.map((path) => fetchDataForPath(path));
    setUnsubscribers(newUnsubscribers);
  }, [fetchDataForPath, paths, unsubscribers]);

  return {
    allAssessments,
    loadingByPath,
    errorByPath,
    refetchByPath,
    refetchAll,
  };
}

export const useDevAssessements = (
  profileAssessments = [],
  devID,
  setter,
  settedAssment = []
) => {
  // const settedAssments = useMemo
  // console.log("useDevAssessements...", { profileAssessments });
  const [assessments, setAssessments] = useState(settedAssment);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const setterRef = useRef(setter);
  setterRef.current = setter;
  const settedAssmentRef = useRef(settedAssment);
  settedAssmentRef.current = settedAssment;

  // console.log("useDevAssessements...", { profileAssessments }, { assessments });
  // Memoize profileAssessments
  const memoizedProfileAssessments = useMemo(
    () => profileAssessments,
    [JSON.stringify(profileAssessments), devID]
  );
  // Memoize fetchVideoURL to avoid re-creation on every render
  const fetchVideoURL = useCallback(
    async (videoPath) => {
      // console.log("fetchVideoURL...", { videoPath });
      // const storage = getStorage();
      const storage = fire.storage();
      // console.log("fetchVideoURL...", { videoPath });
      // console.log("devID...", devID);
      // Construct the reference to the video file
      const videoRef = storage.ref(
        `devs/${devID}/assessments/${videoPath.course}/${videoPath.unit}/${videoPath.question}`
      );

      // console.log("videoRef...", videoRef);

      try {
        // Attempt to get the download URL
        return await videoRef.getDownloadURL();
      } catch (error) {
        console.error("Failed to fetch video URL", error);
        return null;
      }
    },
    [devID]
  );

  // Wrapped in useCallback to avoid re-creation unless dependencies change
  const fetchAssessments = useCallback(async () => {
    if (settedAssmentRef.current.length > 0) {
      setAssessments(settedAssmentRef.current);
      setLoading(false);
      return;
    }

    if (!memoizedProfileAssessments.length) {
      setAssessments([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      // console.log("fetchAssessments...", profileAssessments);
      const assessmentPromises = memoizedProfileAssessments.map(
        async ({ source, units }) => {
          const sourceDocPromise = source.get();
          // console.log("units...", units);
          const unitsDataPromises = units.map((unit) => fetchUnitData(unit));

          const [sourceDoc, unitsData] = await Promise.all([
            sourceDocPromise,
            Promise.all(unitsDataPromises),
          ]);

          const sourceData = {
            ...sourceDoc.data(),
            id: sourceDoc.id,
            ref: sourceDoc.ref,
          };

          return { sourceData, unitsData };
        }
      );

      const newAssessments = await Promise.all(assessmentPromises);
      setAssessments(newAssessments);
      if (setterRef.current) setterRef.current(newAssessments);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch assessments", error);
      setError(error);
      setLoading(false);
    }
  }, [memoizedProfileAssessments, fetchVideoURL]);

  const fetchUnitData = async (unit) => {
    const unitSnapshot = await unit.get();
    const { questions, ...unitData } = unitSnapshot.data();
    // console.log("questions...", questions);
    if (!questions) {
      console.log("No questions available to fetch yet.");
      setRefetch(true);
      return { id: unitSnapshot.id, ...unitData, questionsData: [] };
    }
    const questionsData = await Promise.all(
      questions.map((question) =>
        question ? fetchQuestionData(question) : Promise.resolve(null)
      )
    ).then((results) => results.filter((q) => q)); // Filter out null results if any question was undefined

    return { id: unitSnapshot.id, ...unitData, questionsData };
  };

  const fetchQuestionData = async (question) => {
    try {
      const questionSnapshot = await question.get();
      const questionData = questionSnapshot.data();
      const videoURL = questionData.videoPath
        ? await fetchVideoURL(questionData.videoPath)
        : null;

      const submissionsSnapshot = await questionSnapshot.ref
        .collection("submissions")
        .get();
      const submissionsData = submissionsSnapshot.docs.map((submission) => ({
        id: submission.id,
        ...submission.data(),
        ref: submission.ref,
      }));

      return {
        id: questionSnapshot.id,
        ...questionData,
        videoURL,
        submissionsData,
      };
    } catch (error) {
      console.error("Error fetching question data", error);
      return null; // Return null if there was an issue, handle this gracefully upstream
    }
  };

  useEffect(() => {
    if (settedAssmentRef.current.length > 0) {
      setAssessments(settedAssmentRef.current);
      setLoading(false);
    } else {
      fetchAssessments();
    }
  }, []);

  // Handle refetch
  useEffect(() => {
    if (refetch) {
      const timer = setTimeout(() => {
        fetchAssessments();
        setRefetch(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [refetch, fetchAssessments]);

  // Update assessments when profileAssessments change and settedAssment is empty
  useEffect(() => {
    if (
      memoizedProfileAssessments.length > 0 &&
      settedAssmentRef.current.length === 0
    ) {
      fetchAssessments();
    } else {
      setAssessments([]);
      setLoading(false);
    }
  }, [memoizedProfileAssessments, fetchAssessments]);

  return {
    assessments,
    loading,
    error,
    refetchAssessments: () => setRefetch(true),
  };
};

export const useMultipleDevAssessements = (devs) => {
  // console.log("useMultipleDevAssessements...", { devs });

  const stableDevs = useStableArray(devs);
  const [stackAssessments, setStackAssessments] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState(null);
  //   const [setRefetch, setRefetchState] = useState({});
  // Memoize fetchVideoURL to avoid re-creation on every render
  const fetchVideoURL = useCallback(async (videoPath, devID) => {
    // const storage = getStorage();
    const storage = fire.storage();
    // Construct the reference to the video file
    const videoRef = storage.ref(
      `devs/${devID}/assessments/${videoPath.course}/${videoPath.unit}/${videoPath.question}`
    );

    try {
      // Attempt to get the download URL
      return await videoRef.getDownloadURL();
    } catch (error) {
      console.error("Failed to fetch video URL", error);
      return null;
    }
  }, []);

  // Wrapped in useCallback to avoid re-creation unless dependencies change
  const fetchAssessments = useCallback(
    async (dev) => {
      if (!dev?.profileAssessments) return;
      setLoading((prev) => ({ ...prev, [dev?.id ?? dev?.devDocID]: true }));
      // console.log("devid", dev?.id ?? dev?.devDocID);
      // console.log("dev", dev?.profileAssessments);
      try {
        let assessments = await Promise.all(
          dev?.profileAssessments.map(async ({ source, units }) => {
            const sourceDoc = await source.get();
            const sourceData = {
              ...sourceDoc.data(),
              id: sourceDoc.id,
              ref: sourceDoc.ref,
            };

            const unitsData = await Promise.all(
              units.map(async (unit) => {
                const unitSnapshot = await unit.get();
                const { questions, ...unitData } = unitSnapshot.data();

                if (!questions) {
                  //   setRefetchState((prev) => ({
                  //     ...prev,
                  //     [stack.techRef?.id]: true,
                  //   }));
                  return {
                    id: unitSnapshot.id,
                    ...unitData,
                    questionsData: [],
                  };
                }
                const questionsData = await Promise.all(
                  questions.map(async (question) => {
                    if (!question) return null;
                    const questionSnapshot = await question.get();
                    const questionData = questionSnapshot.data();
                    const videoURL = questionData.videoPath
                      ? await fetchVideoURL(
                          questionData.videoPath,
                          dev?.id ?? dev?.devDocID
                        )
                      : null;
                    const submissionsSnapshot = await questionSnapshot.ref
                      .collection("submissions")
                      .get();
                    const submissionsData = submissionsSnapshot.docs.map(
                      (submission) => ({
                        id: submission.id,
                        ...submission.data(),
                        ref: submission.ref,
                      })
                    );
                    return {
                      id: questionSnapshot.id,
                      ...questionData,
                      videoURL,
                      submissionsData,
                    };
                  })
                );

                return { id: unitSnapshot.id, ...unitData, questionsData };
              })
            );

            return { sourceData, unitsData };
          })
        );
        setLoading((prev) => ({
          ...prev,
          [dev?.id ?? dev?.devDocID]: false,
        }));

        return assessments;
      } catch (error) {
        setLoading((prev) => ({
          ...prev,
          [dev?.id ?? dev?.devDocID]: false,
        }));
        console.error("Failed to fetch assessments", error);
        return null;
      } finally {
        // setLoading(false);
      }
    },
    [fetchVideoURL]
  );

  // refecths when a refetchstate is true on one stack
  //   useEffect(() => {
  //     const assessmentsByStack = { ...stackAssessments };
  //     Object.entries(setRefetch).forEach(([stackId, shouldRefetch]) => {
  //       if (shouldRefetch) {
  //         const stack = techs.find((t) => t.id === stackId);
  //         if (stack) {
  //           fetchAssessments(stack).then((assessments) => {
  //             setRefetchState((prev) => ({ ...prev, [stackId]: false }));
  //             if (assessments?.length > 0) {
  //               assessmentsByStack[stackId] = assessments;
  //               setStackAssessments(assessmentsByStack);
  //             }
  //           });
  //         }
  //       }
  //     });
  //   }, [setRefetch, devs, fetchAssessments]);

  useEffect(() => {
    const loadAssessments = async () => {
      const assessmentsByStack = {};

      try {
        for (const dev of stableDevs) {
          if (!dev.profileAssessments?.length) continue;

          const assessments = await fetchAssessments(dev);
          if (assessments?.length > 0) {
            assessmentsByStack[dev?.id ?? dev?.devDocID] = assessments;
          }
        }

        setStackAssessments(assessmentsByStack);
      } catch (e) {
        setError(e);
      }
    };

    loadAssessments();
  }, [stableDevs, fetchAssessments]);

  return { stackAssessments, loading, error };
};

export const useTechAssessments = (techs, devID) => {
  const [stackAssessments, setStackAssessments] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState(null);
  const [setRefetch, setRefetchState] = useState({});
  // Memoize fetchVideoURL to avoid re-creation on every render
  const fetchVideoURL = useCallback(
    async (videoPath) => {
      // const storage = getStorage();
      const storage = fire.storage();
      // console.log("fetchVideoURL...", { videoPath });
      // console.log("devID...", devID);
      // Construct the reference to the video file
      const videoRef = storage.ref(
        `devs/${devID}/assessments/${videoPath.course}/${videoPath.unit}/${videoPath.question}`
      );

      // console.log("videoRef...", videoRef);

      try {
        // Attempt to get the download URL
        return await videoRef.getDownloadURL();
      } catch (error) {
        console.error("Failed to fetch video URL", error);
        return null;
      }
    },
    [devID]
  );

  // Wrapped in useCallback to avoid re-creation unless dependencies change
  const fetchAssessments = useCallback(
    async (stack) => {
      if (!stack?.assessments) return;
      setLoading((prev) => ({ ...prev, [stack?.techRef?.id]: true }));
      try {
        let assessments = await Promise.all(
          stack?.assessments.map(async ({ source, units }) => {
            const sourceDoc = await source.get();
            const sourceData = {
              ...sourceDoc.data(),
              id: sourceDoc.id,
              ref: sourceDoc.ref,
            };

            const unitsData = await Promise.all(
              units.map(async (unit) => {
                const unitSnapshot = await unit.get();
                const { questions, ...unitData } = unitSnapshot.data();

                if (!questions) {
                  setRefetchState((prev) => ({
                    ...prev,
                    [stack?.techRef?.id]: true,
                  }));
                  return {
                    id: unitSnapshot.id,
                    ...unitData,
                    questionsData: [],
                  };
                }
                const questionsData = await Promise.all(
                  questions.map(async (question) => {
                    if (!question) return null;
                    const questionSnapshot = await question.get();
                    const questionData = questionSnapshot.data();
                    const videoURL = questionData.videoPath
                      ? await fetchVideoURL(questionData.videoPath)
                      : null;
                    const submissionsSnapshot = await questionSnapshot.ref
                      .collection("submissions")
                      .get();
                    const submissionsData = submissionsSnapshot.docs.map(
                      (submission) => ({
                        id: submission.id,
                        ...submission.data(),
                        ref: submission.ref,
                      })
                    );
                    return {
                      id: questionSnapshot.id,
                      ...questionData,
                      videoURL,
                      submissionsData,
                    };
                  })
                );

                return { id: unitSnapshot.id, ...unitData, questionsData };
              })
            );

            return { sourceData, unitsData };
          })
        );
        setLoading((prev) => ({ ...prev, [stack?.techRef?.id]: false }));

        return assessments;
      } catch (error) {
        setLoading((prev) => ({ ...prev, [stack?.techRef?.id]: false }));
        console.error("Failed to fetch assessments", error);
        return null;
      } finally {
        // setLoading(false);
      }
    },
    [fetchVideoURL]
  );

  // refecths when a refetchstate is true on one stack
  useEffect(() => {
    const assessmentsByStack = { ...stackAssessments };
    Object.entries(setRefetch).forEach(([stackId, shouldRefetch]) => {
      if (shouldRefetch) {
        const stack = techs.find((t) => t.techRef?.id === stackId);
        if (stack) {
          fetchAssessments(stack).then((assessments) => {
            setRefetchState((prev) => ({ ...prev, [stackId]: false }));
            if (assessments?.length > 0) {
              const data = { ...assessments };
              assessmentsByStack[stack?.techRef?.id] = assessments;
              setStackAssessments(assessmentsByStack);
            }
          });
        }
      }
    });
  }, [setRefetch, techs, fetchAssessments]);

  useEffect(() => {
    const loadAssessments = async () => {
      const assessmentsByStack = {};

      try {
        for (const stack of techs) {
          if (!stack.assessments?.length) continue;

          const assessments = await fetchAssessments(stack);
          if (assessments?.length > 0) {
            assessmentsByStack[stack?.techRef?.id] = assessments;
          }
        }

        setStackAssessments(assessmentsByStack);
      } catch (e) {
        setError(e);
      }
    };

    loadAssessments();
  }, [techs, devID, fetchAssessments]);

  return { stackAssessments, loading, error };
};

export async function getDevAssessments(dev) {
  if (!dev?.profileAssessments) return null;

  try {
    const assessments = await Promise.all(
      dev?.profileAssessments.map(async ({ source, units }) => {
        const sourceDoc = await source.get();
        const sourceData = {
          ...sourceDoc.data(),
          id: sourceDoc.id,
          ref: sourceDoc.ref,
        };

        const unitsData = await Promise.all(
          units.map(async (unit) => {
            const unitSnapshot = await unit.get();
            const { questions, ...unitData } = unitSnapshot.data();

            if (!questions) {
              return {
                id: unitSnapshot.id,
                ...unitData,
                questionsData: [],
              };
            }

            const questionsData = await Promise.all(
              questions.map(async (question) => {
                if (!question) return null;
                const questionSnapshot = await question.get();
                const questionData = questionSnapshot.data();

                const videoURL = questionData.videoPath
                  ? await fetchVideoURL({
                      devID: dev?.id ?? dev?.devDocID,
                      videoPath: questionData.videoPath,
                    })
                  : null;

                const submissionsSnapshot = await questionSnapshot.ref
                  .collection("submissions")
                  .get();

                const submissionsData = submissionsSnapshot.docs.map(
                  (submission) => ({
                    id: submission.id,
                    ...submission.data(),
                    ref: submission.ref,
                  })
                );

                return {
                  id: questionSnapshot.id,
                  ...questionData,
                  videoURL,
                  submissionsData,
                };
              })
            );

            return {
              id: unitSnapshot.id,
              ...unitData,
              questionsData,
            };
          })
        );

        return { sourceData, unitsData };
      })
    );

    return assessments;
  } catch (error) {
    console.error("Failed to fetch assessments", error);
    return null;
  }
}

export const useAllSubCollectionAssessment = (
  devRef,
  subcollections = ["categories", "sectors", "stacks"]
) => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // console.log("useAllSubCollectionAssessment...", { devID: devRef?.id });
  useEffect(() => {
    if (!devRef) return;

    console.log("useAllSubCollectionAssessment...");

    const unsubscribeList = [];
    let isMounted = true; // To prevent setting state on an unmounted component

    const fetchAssessments = async () => {
      setLoading(true);
      try {
        for (const subcollectionName of subcollections) {
          const subcollectionRef = devRef.collection(subcollectionName);

          const unsubscribe = subcollectionRef.onSnapshot((snapshot) => {
            const subcollectionAssessments = [];

            snapshot.forEach((doc) => {
              const data = doc.data();
              if (data.assessments) {
                subcollectionAssessments.push(
                  ...data.assessments.map((assessment) => ({
                    ...assessment,
                    subcollection: subcollectionName,
                    docId: doc.id,
                    ref: doc.ref,
                  }))
                );
              }
            });

            // Update state only if component is still mounted
            if (isMounted) {
              setAssessments((prevAssessments) => {
                const filteredAssessments = prevAssessments.filter(
                  (a) => a.subcollection !== subcollectionName
                );
                return [...filteredAssessments, ...subcollectionAssessments];
              });
            }
          });

          unsubscribeList.push(unsubscribe);
        }
      } catch (err) {
        console.error("Error fetching assessments:", err);
        if (isMounted) setError(err);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchAssessments();

    return () => {
      isMounted = false;
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  }, [devRef?.id]);

  return { assessments, loading, error };
};
