import React, { useEffect, useState } from "react";
import SpStepper from "../globals/SpStepper/StepperWrapper/StepperWrapper";
import SpStepperElement from "../globals/SpStepper/StepperElement/StepperElement";

import EditIcon from "@mui/icons-material/Edit";
import EndorsmentBadge2 from "../svgs/EndorsementBadge2";
import EmptyStepperIccon from "../svgs/EmptyStepperIcon";

const StepsComponent = ({
  cls,
  title,
  data,
  renderTitle,
  renderInfo,
  renderDesc,
  renderLeft,
  showBullets = true,
  showDesc = true,
  labels = {
    title: "Title",
    info: "Info",
    desc: "Description",
    date1: "Start Date",
    date2: "End Date",
  },
  onItemAdd,
  useDateRange,
  onUpdate,
  initialData,
  renderBadge,
  id,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [steps, setSteps] = useState(data);
  const [newStep, setNewStep] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  // console.log("steps", steps);

  // const onPositionUpdate = ()

  useEffect(() => {
    const sanitizedData = data.map((step, index) => ({
      ...step,
      position: isNaN(step.position) ? index : step.position,
    }));
    setSteps(sanitizedData);
    setNewStep(null);
  }, [data]);

  const handleRemoveStep = async (index) => {
    if (steps?.[index]?.ref) {
      await steps?.[index]?.ref?.delete();
    }
    const updatedSteps = steps.filter((_, i) => i !== index);
    setSteps(updatedSteps);
    setNewStep(null);
  };

  const handleAddStep = () => {
    // console.log("initialData", initialData);
    const emptyStep = {
      ...initialData,
    };
    if ("position" in emptyStep) {
      // get the max position then add 1
      emptyStep.position = steps.length
        ? Math.max(...steps.map((step) => step.position || 0), 0) + 1
        : 1;
    }
    if ("gradDate" in emptyStep) {
      // get the oldest date then decrement this date by 1
      emptyStep.gradDate = new Date(
        Math.min(...steps.map((step) => new Date(step.gradDate))) - 1
      );
    }
    setSteps([...steps, emptyStep]);
    setEditingIndex(steps.length);
    setNewStep(emptyStep);
    setNewStep(null);
  };

  const handleSaveStep = async (index, updatedStep) => {
    // console.log("updatedStep", updatedStep);
    const { dates, editData, imagesFile } = updatedStep;
    const data = {
      dates,
      ...editData,
    };

    onUpdate(data, steps?.[index]?.ref ?? null, imagesFile);

    // await steps?.[index]?.ref?.update(updatedStep);
    const updatedSteps = steps.map((step, i) => (i === index ? data : step));

    setSteps(updatedSteps);
    setEditingIndex(null);
    setNewStep(null);
  };

  const incrementPosition = (index) => {
    const currentPos = steps[index]?.position ?? 0;
    const minPos = Math.min(...steps.map((step) => step.position || 0), 0);

    if (currentPos <= minPos && currentPos === 0) return;

    const newSteps = steps.map((step, i) => {
      if (step.position === currentPos - 1) {
        return { ...step, position: step.position + 1 };
      }
      if (i === index) {
        try {
          step?.ref?.update?.({ position: step.position - 1 });
          return { ...step, position: step.position - 1 };
        } catch (error) {
          console.error("Error updating position:", error);
        }
      }
      return step;
    });

    setSteps(newSteps.sort((a, b) => a.position - b.position));
    setEditingIndex(
      newSteps.findIndex((step) => step.position === currentPos - 1)
    );
  };

  const decrementPosition = (index) => {
    const currentPos = steps[index]?.position ?? 0;
    const maxPos = Math.max(
      ...steps.map((step) => step.position || 0),
      steps.length
    );

    if (currentPos >= maxPos && currentPos >= steps.length) return;

    const newSteps = steps.map((step, i) => {
      if (step.position === currentPos + 1) {
        return { ...step, position: step.position - 1 };
      }
      if (i === index) {
        try {
          step?.ref?.update?.({ position: step.position + 1 });
          return { ...step, position: step.position + 1 };
        } catch (error) {
          console.error("Error updating position:", error);
        }
      }
      return step;
    });

    setSteps(newSteps.sort((a, b) => a.position - b.position));
    setEditingIndex(
      newSteps.findIndex((step) => step.position === currentPos + 1)
    );
  };

  const sortSteps = (steps) => {
    return steps.sort((a, b) => {
      if (a.position !== undefined && b.position !== undefined) {
        if (a.position === b.position) {
          return new Date(b.end) - new Date(a.end);
        }
        return a.position - b.position;
      }
      return new Date(b.gradDate) - new Date(a.gradDate);
    });
  };

  const isEmptyNewStep =
    newStep &&
    !newStep.title &&
    !newStep.info &&
    !newStep.desc &&
    (!newStep.bullets || newStep.bullets.length === 0);

  // console.log("editingIndex", editingIndex);

  // Use sorted steps in rendering
  const sortedSteps = sortSteps(steps);

  const handleCancelStep = (index) => {
    setSteps(data);
    setEditingIndex(null);
    setNewStep(null);
    if (sortedSteps?.length <= 1) {
      setIsEditing(false);
    }
  };

  return (
    <div className={`_spDevPage--right-steper ${cls}`} id={id}>
      <div className="_spDevPage--right-steper_top">
        <div className="_spDevPage--right-steper_top_title">
          <h4>{title}</h4>
          {renderBadge && renderBadge(data?.length < 1)}
        </div>
        <div className="_spDevPage--right-steper_top_action">
          <button
            className="_spDevPage--right-steper_top_action_edit"
            onClick={() => {
              setIsEditing(!isEditing);

              setEditingIndex(null);
              setNewStep(null);
              // console.log(isEditing);
              // console.log(sortSteps?.length);
              if (sortSteps?.length <= 1 && !isEditing) {
                handleAddStep();
              } else {
                setSteps(data);
              }
            }}
          >
            <EditIcon />
            <span>{isEditing ? "Cancel" : "Edit"}</span>
          </button>
        </div>
      </div>
      <SpStepper
        isEnableEdit={isEditing}
        onAdd={!isEmptyNewStep ? handleAddStep : null}
        initialData={initialData}
        isEmpty={sortedSteps?.length === 0}
      >
        {sortedSteps?.length > 0 ? (
          sortedSteps?.map((item, index) => (
            <SpStepperElement
              key={index}
              index={index}
              title={renderTitle(item)}
              info={renderInfo(item)}
              desc={renderDesc && renderDesc(item)}
              isEnableEdit={isEditing}
              bullets={item?.bullets || []}
              showBullets={showBullets}
              showDesc={showDesc}
              onRemove={() => handleRemoveStep(index)}
              onSave={(updatedStep) => handleSaveStep(index, updatedStep)}
              onCancel={() => handleCancelStep(index)}
              enableEditing={index === editingIndex}
              labels={labels}
              useDateRange={useDateRange}
              dates={{
                start: item?.start || item?.gradDate,
                end: item?.end || item?.gradDate,
              }}
              itemData={item}
              incrementPosition={(e) => {
                e.stopPropagation();
                incrementPosition(index);
              }}
              decrementPosition={(e) => {
                e.stopPropagation();
                decrementPosition(index);
              }}
              onItemEdit={(index) => {
                setEditingIndex(index);
                setNewStep(null);
              }}
              showDownArrow={index !== sortedSteps.length - 1}
              showUpArrow={index !== 0}
              {...props}
            />
          ))
        ) : !isEditing ? (
          <div className="_spDevPage--right-steper_empty">
            <div className="_spDevPage--right-steper_empty__content">
              <EmptyStepperIccon />
              <div className="_spDevPage--right-steper_empty__content-text">
                <h3>Help us fill the gaps</h3>
                <p>we need all the help we can get</p>
              </div>
            </div>
          </div>
        ) : null}
      </SpStepper>
    </div>
  );
};

export default StepsComponent;
