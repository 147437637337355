import React, { useEffect, useState } from "react";
import HiddenCard from "./HiddenCard";

export default function ProjectCard(props) {
  const [showCard, setShowCard] = useState(false);
  const [added, setAdded] = useState(false);
  const [className, setClassName] = useState("hidden_card-left");
  const description = props.project.description.substring(0, 100) + "...";

  // id for each project card
  const stackId = "Stack_cardp" + props.index;

  const cardClick = () => {
    if (showCard === true) {
      setShowCard(false);
    } else {
      setShowCard(true);
    }
  };

  useEffect(() => {
    if (showCard) {
      // console.log("props.project", props.project);
      props.setSelectedData(props.project);
    }
  }, [showCard]);

  if (showCard) {
    // close the modal when we click  outside the data-request_form box
    window.addEventListener("mouseup", (e) => {
      let subBox = document.getElementById(stackId);
      let dataEdit = document.getElementById("collect_edit_projects");
      try {
        if (!subBox.contains(e.target) && !dataEdit.contains(e.target)) {
          setShowCard(false);
        }
      } catch (e) {
        return;
      }
    });
  }

  useEffect(() => {
    let card = document.getElementById(stackId);
    if (card) {
      // if the card exist we will get the card position from the screen to set the
      // the hidden card position
      let rect = card.getBoundingClientRect();

      //if the left side of the hidden card is less than 700 we will show the hidden card in the right
      if (rect.left < 700) {
        setClassName("hidden_card-right");
      } else {
        setClassName("hidden_card-left");
      }
    }
  }, []);

  const isExist = props?.projExp?.filter(
    (exp) => exp.projectRef?.id === props.project.id
  ).length;

  return (
    <>
      {props.useExist && isExist ? (
        <div className="_spTechskills-chip _spTechskills-chip-available">
          <div className="_spTechskills-chip__text" onClick={cardClick}>
            <img
              src={props.project?.logoStorage}
              alt={props.project.name}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://via.placeholder.com/150x150.png?text=No+Image";
              }}
            />

            <div className="techskill_name">
              <p>{props.project.name}</p>
            </div>
          </div>
        </div>
      ) : (
        <button onClick={cardClick} className="_spTechskills-chip">
          <div className="_spTechskills-chip__text" id={stackId}>
            <img
              src={props.project?.logoStorage}
              alt={props.project.name}
              className="img"
            />
            <div className="techskill_name">
              <p>{props.project.name}</p>
            </div>
          </div>
          {props.showHiddenCard ? (
            <HiddenCard
              className={className}
              showCard={showCard}
              setShowCard={setShowCard}
              title={props.project.name}
              category={props.project.catData?.name}
              description={props.project.description}
              firebase={props.firebase}
              devId={props.devId}
              setAdded={setAdded}
              added={added}
              project={props.project}
              categoriesObject={props.categoriesObject}
            />
          ) : (
            <></>
          )}
        </button>
      )}
    </>
  );
}
