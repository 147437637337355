import React, { useState, useEffect, useContext, useMemo, memo } from "react";
import {
  GetNowTimeStamp,
  addOrUpdateDocFromPath,
  colRef,
  db,
  fire,
} from "../globals/services";
import {
  IndividualAvail,
  Vacation,
  TechStacks,
  DevRate,
  Education,
  Experience,
} from "../pages/Developer/DevGrids";
import { Avatar } from "../pages/Developer/DevInfo";
import DevDetails from "../pages/Resources/Profile/DevDetails";
import CrossPlatform from "../pages/Resources/Profile/components/Account_Cross_Platform";
import ProjectsExperience from "../pages/Developer//DevGrids/ProjectsExperience";
import FacadeProfiles from "../globals/FacadeProfiles";
import { useCurrentDevRate } from "../hooks/useCurrentDevRate";
import { useCurrentVendorHolidays } from "../hooks/useCurrentVendorHolidays";
import { GlobalVendorContex } from "../Firebase";
import Matches from "../pages/Developer//DevGrids/Matches";
import { useVendorAllData } from "../hooks/useVendor";
import {
  useDev,
  useDevAssessements,
  useDevFull,
  useDevRateRanges,
} from "../hooks/useDev";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  useCategories,
  useDomains,
  useStacks,
  useTechAssessments,
} from "../hooks/useTechs";

import { assignUnitToUser } from "../utils/MainUtils";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import { CircularProgress } from "@mui/material";
import { setNestedProperty } from "../globals/helpers";
import EndorsmentBadge from "../svgs/endorsementBadge";
import EndorsmentBadge2 from "../svgs/EndorsementBadge2";
import { useAllAssessments } from "../hooks/useDevAssessments";
// import '../../../../css/Account.css';

const STACKFETCHLIMIT = 50;
const PAGELIMIT = 40;

// Similar to the DevData.js in the Developer folder but we are not using
// local storage, we want all the same grids and info
const MainDev = (props) => {
  const { id } = useParams();
  const router = useNavigate();
  const location = useLocation();
  const {
    stacks: allStacks,
    lastVisible,
    onNext,
  } = useStacks(STACKFETCHLIMIT, 50);
  const {
    domains: allDomains,
    lastVisible: domainLastVisible,
    onNext: domainOnNext,
  } = useDomains(STACKFETCHLIMIT, 50);

  const {
    categories: allCategories,
    lastVisible: categoryLastVisible,
    onNext: categoryOnNext,
  } = useCategories(STACKFETCHLIMIT, 50);

  const [devMatches, setDevMatches] = useState([]);
  // The metadata for the matches.
  const [matchesMetaData, setMatchesMetaData] = useState(null);
  const [matchesLoading, setMatchesLoading] = useState(false);
  const [reComputingMatches, setReComputingMatches] = useState(false);
  const [assementClicked, setAssessmentClicked] = useState(false);
  const [clickedStacksAssessment, setClickedStacksAssessment] = useState({});
  // const [previousPath, setPreviousPath] = useState("");
  const [profile, setProfile] = useState({
    url: "",
    name: "",
    id: "",
  });

  const [isResumeUploadeding, setIsResumeUploadeding] = useState(false);

  // useEffect(() => {
  //   // console.log("location: ", location);
  //   // Store the previous path in the state
  //   setPreviousPath(location?.state?.from || "/resources");
  // }, [location]);

  const devId = useMemo(() => {
    // const storageId = localStorage.getItem("dev");

    // if (storageId) return storageId;
    if (props?.auth?.devRef?.id) {
      return props?.auth?.devRef?.id;
    }
    if (id) return id;

    if (props?.devId) return props?.devId;

    return null;
  }, [props?.auth?.devRef?.id, id, props?.devId]);

  const isDev = useMemo(() => {
    if (props?.auth?.devRef?.id || props?.devId) return true;
    return false;
  }, [props?.auth?.devRef?.id, props?.devId]);

  const isVendor = useMemo(() => {
    if (props?.auth?.vendor?.id) return true;
    return false;
  }, [props?.auth?.vendor?.id]);

  // console.log("devId: ", devId);

  const { data, loading } = useDev(devId);
  // console.log("data: ", data);
  const {
    stacks,
    avails,
    vacations,
    vacationsLoading,
    education,
    experience,
    projectExp,
    devPublic,
    domains,
    projects,
  } = useDevFull(devId);

  const { paths, keys } = useMemo(() => {
    return {
      paths: [
        // `/devs/${devId}/devPublic/profile`, // doc path (even segments => doc)
        `/devs/${devId}/stacks`, // collection path
        `/devs/${devId}/categories`, // collection path
        `/devs/${devId}/sectors`, // collection path
      ],
      keys: {
        [`/devs/${devId}/devPublic/profile`]: "profileAssessments",
        // others default to "assessments"
      },
    };
  }, [devId]);

  const {
    allAssessments,
    loadingByPath,
    error: allAssessmentsError,
    refetchAll,
  } = useAllAssessments({
    devID: devId,
    paths,
    assessmentFieldKeys: keys,
  });

  const pathMap = useMemo(
    () => ({
      stacks: `/devs/${devId}/stacks`,
      sectors: `/devs/${devId}/sectors`,
      categories: `/devs/${devId}/categories`,
    }),
    [devId]
  );

  // console.log({ allAssessments, loadingByPath });

  const { vendor, vendorPublic } = useVendorAllData(data?.vendorRef?.id);

  const { assessments, loading: assessementLoading } = useDevAssessements(
    data?.profileAssessments,
    devId
  );
  // console.log("assessments loading: ", assessementLoading);

  // const {
  //   stackAssessments: stackAssessmentData,
  //   loading: stackAssessmentsLoading,
  // } = useTechAssessments(stacks, devId);

  // console.log("loading stackAssessments: ", stackAssessmentData);

  useEffect(() => {
    if (data?.first) {
      globalData?.setDevName(`${data?.first} ${data?.last}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.first, data?.last]);

  const vendorMaxes = useMemo(() => {
    if (!vendor) return { maxSecLev: "L0", maxRates: [] };
    let maxSecLev = "L0";
    let maxRates = [];
    Object.entries(vendor?.security).forEach((entry) => {
      const [key, value] = entry;

      if (value.approved && key > maxSecLev) {
        maxSecLev = key;
      }
      if (value.approved) maxRates.push(value);
    });
    maxRates.sort((a, b) => a.level - b.level);
    return { maxSecLev, maxRates };
  }, [vendor]);

  const { devRates, minDate } = useDevRateRanges(devId, vendorMaxes.maxSecLev);

  useEffect(() => {
    // const storageId = localStorage.getItem("dev");

    if (
      props?.auth?.devRef?.id ||
      !data ||
      props?.auth?.isSuperPartner ||
      props?.devId
    )
      return;

    if (data?.vendorRef?.id !== props?.auth?.vendor?.id) {
      router(`/resources`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.auth?.vendor?.id,
    data?.vendorRef?.id,
    props?.auth?.devRef?.id,
    props?.auth?.isSuperPartner,
    data?.vendorRef?.id,
  ]);

  const globalData = useContext(GlobalVendorContex);

  const enableSubmit = useMemo(() => {
    if (data?.draft) {
      if (stacks?.length > 0 && domains?.length > 0 && projects?.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  }, [data?.draft, stacks?.length, domains?.length, projects?.length]);

  const { devRate } = useCurrentDevRate(devId);

  const { vendorHolidays } = useCurrentVendorHolidays(
    props?.vendor?.id ?? data?.vendorRef?.id
  );

  const onStacksPaginate = async (e, page) => {
    // Assuming you have a state variable for tracking the current page and total number of stacks
    const isLastPage = page * PAGELIMIT >= allStacks.length;

    if (isLastPage && lastVisible) {
      // Fetch next 50 stacks
      onNext();
    }
  };

  const onDomainsPaginate = async (e, page) => {
    // Assuming you have a state variable for tracking the current page and total number of stacks
    const isLastPage = page * 12 >= allDomains.length;

    if (isLastPage && domainLastVisible) {
      // Fetch next 50 stacks
      domainOnNext();
    }
  };

  const onCategoriesPaginate = async (e, page) => {
    // Assuming you have a state variable for tracking the current page and total number of stacks
    const isLastPage = page * 12 >= allCategories.length;

    if (isLastPage && categoryLastVisible) {
      // Fetch next 50 stacks
      categoryOnNext();
    }
  };

  const toolbarOptions = ["Edit", "Delete", "Update", "Cancel"];

  useEffect(() => {
    if (!devId) return;
    const subScrubMatches = colRef(`devs/${devId}/matches`).onSnapshot(
      async (snap) => {
        if (!matchesLoading) setMatchesLoading(true);
        let matches = [];
        if (snap?.docs?.length > 0) {
          await Promise.all(
            snap?.docs?.map(async (matchDoc) => {
              const { createdOn, source, mScore } = matchDoc.data();
              const matchType =
                source?.path?.match("companies")?.length > 0 ? "C" : "R";
              const sourceData = {
                ...(await source?.get())?.data(),
                sourceDataRef: source,
              };
              const reqStacks = [];
              const reqDomains = [];
              const reqProducts = [];
              if (matchType === "C") {
                const compStacksQuery = await colRef(
                  `${source?.path}/stacks`
                ).get();
                let stacksList = [];
                compStacksQuery?.docs?.map(async (docSnap) => {
                  const stackData = stacks?.find(
                    (stack) => stack?.id === docSnap?.data()?.ref?.id
                  );
                  stacksList.push(stackData);
                });
                stacksList?.sort((a, b) => {
                  let x = a?.id?.toLowerCase(),
                    y = b?.id?.toLowerCase();
                  return x === y ? 0 : x > y ? 1 : -1;
                });
                matchDoc
                  ?.data()
                  ?.technicalMeta?.stacks?.map((isPresent, index) => {
                    if (isPresent) {
                      reqStacks.push(stacksList[index]);
                    }
                  });

                const compProductsQuery = await colRef(
                  `${source?.path}/products`
                ).get();
                let productsList = [];
                await Promise.all(
                  compProductsQuery?.docs?.map(async (docSnap) => {
                    const stackData = (
                      await docSnap?.data()?.ref?.get()
                    )?.data();
                    productsList.push(stackData);
                  })
                );
                productsList?.sort((a, b) => {
                  let x = a?.id?.toLowerCase(),
                    y = b?.id?.toLowerCase();
                  return x === y ? 0 : x > y ? 1 : -1;
                });
                matchDoc
                  ?.data()
                  ?.technicalMeta?.products?.map((isPresent, index) => {
                    if (isPresent) {
                      reqProducts.push(productsList[index]);
                    }
                  });

                const compCategorization = sourceData?.categorization ?? [];
                let categoriesList = [];
                await Promise.all(
                  compCategorization?.map(async (catRef) => {
                    const catData = (await catRef?.get())?.data();
                    categoriesList.push(catData);
                  })
                );
                categoriesList?.sort((a, b) => {
                  let x = a?.id?.toLowerCase(),
                    y = b?.id?.toLowerCase();
                  return x === y ? 0 : x > y ? 1 : -1;
                });
                matchDoc
                  ?.data()
                  ?.technicalMeta?.sectors?.map((isPresent, index) => {
                    if (isPresent) {
                      reqDomains.push(categoriesList[index]);
                    }
                  });
              } else {
                const devMatchStacks = sourceData?.requirements?.stacks;
                let stacksList = [];
                if (devMatchStacks)
                  devMatchStacks?.map(async (stackRef) => {
                    const stackFound = stacks?.find(
                      (stack) => stack?.id === stackRef
                    );
                    stacksList.push(stackFound);
                  });
                stacksList?.sort((a, b) => {
                  let x = a?.id?.toLowerCase(),
                    y = b?.id?.toLowerCase();
                  return x === y ? 0 : x > y ? 1 : -1;
                });
                matchDoc?.data()?.technicalMeta?.map((isPresent, index) => {
                  if (isPresent) {
                    reqStacks.push(stacksList[index]);
                  }
                });
              }
              let dataToPush = {
                matchedOn: createdOn,
                matchedOnString:
                  createdOn?.toDate().toDateString().slice(4, 25) +
                  ` (${Math.floor(
                    (GetNowTimeStamp() - createdOn) / (60 * 60 * 24)
                  )}d)`,
                link: source?.path,
                matchType: matchType,
                sourceData: { ...(sourceData ?? {}) },
                ...matchDoc.data(),
              };
              dataToPush["pointsTitle"] = "MATCH RESULTS";
              dataToPush["pointsData"] = [];
              if (matchType === "C") {
                dataToPush["pointsData"] = [
                  [
                    true,
                    reqProducts?.length > 0,
                    `Products Match (${reqProducts?.length}/${
                      matchDoc?.data()?.technicalMeta?.products?.length
                    })`,
                    reqProducts?.map((prod) => prod?.name)?.join(", "),
                  ],
                  [
                    true,
                    reqDomains?.length > 0,
                    `Domain Knowledge Match (${reqDomains?.length}/${
                      matchDoc?.data()?.technicalMeta?.sectors?.length
                    })`,
                    reqDomains.map((dom) => dom?.name).join(", "),
                  ],
                  [
                    true,
                    reqStacks?.length > 0,
                    `Technical Match (${reqStacks?.length}/${
                      matchDoc?.data()?.technicalMeta?.stacks?.length
                    })`,
                    reqStacks.map((stack) => stack?.name).join(", "),
                  ],
                ];
                dataToPush["descTitle"] = "COMPANY INFORMATION";
                dataToPush["descData"] = {
                  title: sourceData?.crm?.data?.properties?.name?.value,
                  subTitle: sourceData?.crm?.data?.properties?.website?.value,
                  image: sourceData?.profile?.logoUrl,
                  desc: sourceData?.crm?.data?.properties?.description?.value,
                };
                dataToPush["buttons"] = [];
              } else {
                let tempSourcePositions = [];
                let positionTitles = [];
                let positionTitlesAlternates = [];
                if (sourceData?.positions) {
                  await Promise.all(
                    sourceData?.positions?.map(async (sourcePos) => {
                      const posTitleData = (
                        await sourcePos?.title?.get()
                      ).data();
                      tempSourcePositions.push({
                        ...sourcePos,
                        titleData: [
                          posTitleData?.label,
                          ...(posTitleData?.alternates ?? []),
                        ],
                      });
                      positionTitles.push(posTitleData?.label);
                      positionTitlesAlternates.push(posTitleData?.alternates);
                    })
                  );
                }
                const tempPosition = tempSourcePositions?.filter((posi) =>
                  posi?.titleData?.includes(data?.position)
                );
                const tempPositionMatched = tempPosition?.length > 0;
                const tempRate = tempPositionMatched
                  ? `$${tempPosition[0]?.minRate} - $${tempPosition[0]?.maxRate}`
                  : (data?.devRate ?? 1) >
                    (sourceData?.originalTeamCardData?.hourlyPrice ?? 1)
                  ? `$${
                      sourceData?.originalTeamCardData?.hourlyPrice ?? 1
                    } - $${data?.devRate ?? 1}`
                  : `$${data?.devRate ?? 1} - $${
                      sourceData?.originalTeamCardData?.hourlyPrice ?? 1
                    }`;
                const tempLocations =
                  sourceData?.originalTeamCardData?.countryList?.join(", ");
                const tempPositionsString = positionTitles?.join(", ");
                dataToPush["pointsData"] = [
                  [
                    true,
                    matchDoc?.data()?.matchScore?.location ?? false,
                    "Location",
                    tempLocations,
                  ],
                  [
                    true,
                    matchDoc?.data()?.matchScore?.position ?? false,
                    "Position",
                    tempPositionsString,
                  ],
                  [
                    true,
                    matchDoc?.data()?.matchScore?.rates ?? false,
                    "Rates",
                    tempRate,
                  ],
                  [
                    true,
                    matchDoc?.data()?.matchScore?.availability ?? false,
                    "Availability",
                    sourceData?.originalTeamCardData?.startDate &&
                    sourceData?.originalTeamCardData?.endDate
                      ? `Starts between ${sourceData?.originalTeamCardData?.startDate
                          ?.toDate()
                          ?.toDateString()
                          ?.slice(4, 25)}
                    & ${sourceData?.originalTeamCardData?.endDate
                      ?.toDate()
                      ?.toDateString()
                      ?.slice(4, 25)}`
                      : "N/A",
                  ],
                  [
                    true,
                    matchDoc?.data()?.matchScore?.technical ?? false,
                    `Technical Requirements (${reqStacks?.length}/${
                      matchDoc?.data()?.technicalMeta?.length ?? "?"
                    })`,
                    reqStacks
                      .map((stack) => stack?.name ?? stack?.title ?? "")
                      .join(", "),
                  ],
                ];
                dataToPush["descTitle"] = "DESCRIPTION";
                dataToPush["descData"] = { desc: sourceData?.description };
                dataToPush["buttons"] = [
                  {
                    name: "View Requirement",
                    action: () => {
                      window.open(
                        `./requirements/${sourceData?.sourceDataRef?.parent?.parent?.id}`
                      );
                    },
                  },
                ];
              }

              matches.push({ ...dataToPush });
            })
          );
          setDevMatches(matches);
          setMatchesLoading(false);
          setReComputingMatches(false);
        } else {
          setMatchesLoading(false);
          setReComputingMatches(false);
        }
      }
    );
    return subScrubMatches;
  }, [devId, stacks, domains, projects]);

  // console.log("globalData?.projectsData: ", globalData?.projectsData);

  useEffect(() => {
    if (devId) {
      // Listen for changes in the company's matchesMeta subcollection.
      const matchesSubscription = colRef(`devs/${devId}/matchesMeta`)
        .orderBy("createdOn", "desc")
        .limit(1)
        .onSnapshot((docChanges) => {
          if (docChanges.docs.length) {
            setMatchesMetaData(docChanges.docs[0].data());
          } else {
            setMatchesMetaData(null);
          }
          setReComputingMatches(false);
        });
      return matchesSubscription;
    }
  }, [devId]);

  const devHolidays = useMemo(() => {
    let daysOff = [];
    vacations?.forEach((vacation) => {
      daysOff.push({
        from: vacation.from,
        to: vacation.to,
        label: vacation.label,
      });
    });
    vendorHolidays?.forEach((holiday) => {
      daysOff.push({
        from: holiday.from,
        to: holiday.to,
        label: holiday.description,
      });
    });
    return daysOff;
  }, [vendorHolidays, vacations]);

  const onToggle = async (e, fieldName) => {
    if (devId) {
      let updates = {};
      // if (fieldName !== "featured") {
      if (fieldName === "crossVendor") {
        updates["crossVendor"] = e.checked;
      }
      if (fieldName === "status") {
        updates["status"] = e.checked;
        if (e.checked) {
          updates["triggerSearchCompute"] = new Date();
        }
      }
      if (fieldName === "leadTime") {
        updates = {
          leadTime: {
            lastUpdated: new Date(),
            leadTimeOn: e.checked,
          },
        };
      }
      if (fieldName === "discovery") {
        updates = {
          discovery: e.checked,
        };
      }
      if (fieldName === "featured") {
        updates = {
          featured: e.checked,
        };
      }
      await props.firebase.updateDev(devId, updates);
    }
    // else {
    //   updates = {
    //     featured: e.checked,
    //   };
    //   await props.firebase.updateDevPublic(devId, updates);
    // }
  };

  const updateDevData = async (data) => {
    let updates = {};
    if (data?.name === "linkedinUrl") {
      updates["linkedIn"] = {
        profileLastUpdatedOn: new Date(),
        profileUrl: data.value,
      };
    }
    if (data?.name === "email") {
      updates["email"] = data.value;
    }
    if (data?.name === "leadDays") {
      updates = {
        leadTime: {
          lastUpdated: new Date(),
          leadDays: parseInt(data.value),
        },
      };
    }
    props.firebase.updateDev(devId, updates);
  };

  const handleDevDetailUpdate = async (field, update) => {
    const updateData = {
      lastUpdated: new Date(),
    };

    if (field === "linkedIn") {
      updateData["linkedIn"] = {
        profileLastUpdatedOn: new Date(),
        profileUrl: update,
      };
    } else if (field === "leadTime") {
      updateData["leadTime"] = {
        lastUpdated: new Date(),
        leadTime: update,
        leadTimeDays: 15,
      };
    } else {
      setNestedProperty(updateData, field, update);
    }

    // Debug output (optional)
    // console.log("updateData: ", updateData);

    // Perform the update and return the result
    return data?.ref.update(updateData);
  };

  const onDraftSubmit = () => {
    addOrUpdateDocFromPath(`devs/${devId}`, { draft: false });

    // Updating lastUpdated for the dev
    props.firebase.updateDev(devId, {});
  };

  // console.log("dev data: ", data);

  const fetchUnitDocIds = async (defaultRefPath) => {
    const unitsCollectionRef = db
      .collection(`${defaultRefPath}/units`)
      .orderBy("index", "asc");
    const snapshot = await unitsCollectionRef.get();
    return snapshot.docs.map((doc) => doc.ref); // Extract and return IDs
  };

  // Ensure you have Firebase initialized and imported

  const onAnswerProfileQuestions = async () => {
    // Prevents re-execution if already clicked
    if (assementClicked) {
      console.log("Double entries aren't allowed");
      return;
    }

    setAssessmentClicked(true);
    const devOrgRef =
      data?.orgRef ?? db.doc(`/organisations/T6BAcTjwbXleCFpmWTmu`);
    const devId = data?.userRef; // Ensure you have the dev's ID from the data

    if (!devOrgRef || !devId) {
      throw new Error("Missing required data for transaction");
    }

    // Start the transaction
    try {
      await db.runTransaction(async (transaction) => {
        // Get the organization document
        const orgDoc = await transaction.get(devOrgRef);

        if (!orgDoc.exists) {
          throw new Error("Organization document does not exist.");
        }

        // Get the devAssessmentDefaults array from the organization document
        const devAssessmentDefaults = orgDoc.data().devAssessmentDefaults;

        // Placeholder for new profileAssessments
        let newProfileAssessments = [];

        // Iterate over each assessment default
        for (const defaultRef of devAssessmentDefaults) {
          const profileAssessmentData = {
            source: defaultRef,
            units: [],
          };

          const unitsRefs = await fetchUnitDocIds(defaultRef?.path);

          let unitCounter = 0;
          for (const unitRef of unitsRefs) {
            const unitSnapShot = await transaction.get(unitRef);
            const unitData = unitSnapShot.data();
            const unitReference = unitSnapShot?.ref;
            const isLastUnitOfTheCourse = unitCounter === unitsRefs.length - 1;

            // Check if the unit already exists in the careerPrep collection
            const userDoc = await data?.ref.get();
            const profileAssessments = userDoc?.data().profileAssessments;

            // console.log({ profileAssessments });

            if (
              profileAssessments &&
              profileAssessments?.some((pa) => pa.units?.length > 0)
            ) {
              console.log(
                "Profile assessments already exist, not assigning unit"
              );
            } else {
              // assign the unit
              const careerPrepRef = await assignUnitToUser(
                db,
                { name: unitData?.name, reference: unitReference },
                data?.userRef,
                isLastUnitOfTheCourse,
                fire
              );

              profileAssessmentData.units.push(careerPrepRef);
            }

            unitCounter += 1;
          }

          newProfileAssessments.push(profileAssessmentData);
        }

        // Update the user document with new profileAssessments
        transaction.update(data?.ref, {
          profileAssessments: newProfileAssessments,
        });
      });

      console.log("Transaction successfully committed!");
      // invokes the Oratio package on answer profile questions
      props.setIsOutSideBtnClicked(true);
      // invokes the Oratio package on every click
      props.setChatBtnClickCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Transaction failed: ", error);
    } finally {
      setAssessmentClicked(false);
    }
  };

  const onCreateStackAssessment = async (sourceRef, stackRef) => {
    // console.log("sourceRef: ", sourceRef);
    // console.log("stackRef: ", stackRef);
    const userRef = data?.userRef;

    if (!sourceRef || !userRef) {
      throw new Error("Missing required data for transaction");
    }
    setClickedStacksAssessment((prev) => ({ ...prev, [stackRef.id]: true }));

    try {
      await db.runTransaction(async (transaction) => {
        const stackDoc = await transaction.get(stackRef);
        const unitsCollectionRef = db.collection(`${sourceRef?.path}/units`);
        const snapshot = await unitsCollectionRef.get();
        const units = snapshot.docs.map((doc) => doc.ref); // Extract and return IDs

        if (!stackDoc.exists) {
          throw new Error("Stack or Source document does not exist.");
        }

        const stackData = stackDoc.data();
        const stackReference = stackDoc.ref;

        const assessments = stackData?.assessments ?? [];

        // verify if the source is already in the assessments
        const sourceExists = assessments.some(
          (assessment) => assessment.source?.id === sourceRef?.id
        );

        if (sourceExists) {
          throw new Error("Assessment already exists for this source");
        }

        const stackAssessmentData = {
          source: sourceRef,
          units: [],
        };

        let unitCounter = 0;

        for (const unitRef of units) {
          const unitSnapShot = await transaction.get(unitRef);
          const unitData = unitSnapShot.data();
          let unitReference = unitSnapShot?.ref;
          let isLastUnitOfTheCourse = false;

          if (unitCounter === units.length - 1) {
            isLastUnitOfTheCourse = true;
          }

          const careerPrepRef = await assignUnitToUser(
            db,
            { name: unitData?.name, reference: unitReference },
            userRef,
            isLastUnitOfTheCourse,
            fire
          );

          unitCounter += 1;
          stackAssessmentData.units.push(careerPrepRef);
        }

        assessments.push(stackAssessmentData);

        transaction.update(stackReference, {
          assessments: assessments,
        });
      });

      // invokes the Oratio package on create stack assessment
      props.setIsOutSideBtnClicked(true);
      // invokes the Oratio package on every click
      props.setChatBtnClickCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Transaction failed: ", error);
      setClickedStacksAssessment((prev) => ({
        ...prev,
        [stackRef.id]: false,
      }));
    } finally {
      setClickedStacksAssessment((prev) => ({
        ...prev,
        [stackRef.id]: false,
      }));
    }
  };

  const clickedRequest = () => {
    props.setIsRequest(true);
  };

  const renderBadge = (isEmpty) => {
    if (isEmpty) {
      return <EndorsmentBadge fill="#888888" />;
    } else {
      return <EndorsmentBadge2 />;
    }
  };

  const Visual = () => {
    // Conditional rendering for dev profile

    let display;
    if (props.devPage === "dev") {
      display = (
        <div style={{ marginTop: "15px" }}>
          <div className="_spDevPage">
            <div className="_spDevPage--content">
              <LeftSection
                devData={data}
                devPublic={devPublic}
                vendorPublic={vendorPublic}
                vendor={vendor}
                devId={devId}
                firebase={props.firebase}
                profile={profile}
                setProfile={setProfile}
                handleDevDetailUpdate={handleDevDetailUpdate}
                isDev={isDev}
                renderBadge={renderBadge}
                isResumeUploadeding={isResumeUploadeding}
                setIsResumeUploadeding={setIsResumeUploadeding}
              />
              <RightSection
                profileUrl={profile?.url}
                devData={data}
                devPublic={devPublic}
                vendorPublic={vendorPublic}
                vendor={vendor}
                devId={devId}
                onAnswerProfileQuestions={onAnswerProfileQuestions}
                assessments={assessments}
                assementClicked={assementClicked}
                assessementLoading={assessementLoading}
                educations={education}
                workExperiences={experience}
                projectExperiences={projectExp}
                handleDevDetailUpdate={handleDevDetailUpdate}
                stackExp={stacks}
                domainExp={domains}
                projExp={projects}
                firebase={props.firebase}
                allPositions={globalData?.orgPositions}
                isVendor={isVendor}
                isDev={isDev}
                isSuperPartner={props?.auth?.isSuperPartner}
                renderBadge={renderBadge}
                devPage={props.devPage}
                setDevPage={props.setDevPage}
              />
            </div>
          </div>
        </div>
      );
    } else if (props.devPage === "techs") {
      display = (
        <TechStacks
          devId={devId}
          devData={data}
          stacksBox={stacks}
          stackExp={stacks}
          firebase={props.firebase}
          devPage={props.devPage}
          isRequest={props.isRequest}
          setIsRequest={props.setIsRequest}
          allStacks={allStacks}
          onStacksPaginate={onStacksPaginate}
          onCreateStackAssessment={onCreateStackAssessment}
          stackAssessmentData={allAssessments?.[pathMap?.stacks]}
          clickedStacksAssessment={clickedStacksAssessment}
          stackAssessmentsLoading={loadingByPath?.[pathMap?.stacks]}
          clickedRequest={clickedRequest}
          setTechStackData={props.setTechStackData}
          setIsOutSideBtnClicked={props.setIsOutSideBtnClicked}
          setChatBtnClickCount={props.setChatBtnClickCount}
          title={"Your Tech Stacks"}
          subTitle={
            "Add in your stack experience, in a much details as you can."
          }
          emptyStackTitle={"Lost in space"}
          emptyStackSubTitle={
            "We don’t have any information about your tech skills."
          }
          type="stacks"
          visibleCls="techs"
          searchPlaceholder="Python"
        />
      );
    } else if (props.devPage === "domain") {
      display = (
        <TechStacks
          devId={devId}
          devData={data}
          stackExp={domains}
          firebase={props.firebase}
          devPage={props.devPage}
          isRequest={props.isRequest}
          setIsRequest={props.setIsRequest}
          allStacks={allDomains}
          clickedRequest={clickedRequest}
          title={"Your Domain Knowledge"}
          onCreateStackAssessment={onCreateStackAssessment}
          stackAssessmentData={allAssessments?.[pathMap?.sectors]}
          clickedStacksAssessment={clickedStacksAssessment}
          stackAssessmentsLoading={loadingByPath?.[pathMap?.sectors]}
          setTechStackData={props.setTechStackData}
          setIsOutSideBtnClicked={props.setIsOutSideBtnClicked}
          setChatBtnClickCount={props.setChatBtnClickCount}
          subTitle={
            "Have you worked and acquired non-technical knowledge about an industry?"
          }
          emptyStackTitle={"Lost in space"}
          emptyStackSubTitle={
            "We don’t have any information about your domain knowledge."
          }
          type="domains"
          visibleCls="domain"
          searchPlaceholder="Agriculture"
        />
      );
    } else if (props.devPage === "project") {
      display = (
        <TechStacks
          devId={devId}
          devData={data}
          stackExp={projects}
          firebase={props.firebase}
          devPage={props.devPage}
          isRequest={props.isRequest}
          setIsRequest={props.setIsRequest}
          allStacks={allCategories}
          clickedRequest={clickedRequest}
          onCreateStackAssessment={onCreateStackAssessment}
          stackAssessmentData={allAssessments?.[pathMap?.categories]}
          clickedStacksAssessment={clickedStacksAssessment}
          stackAssessmentsLoading={loadingByPath?.[pathMap?.categories]}
          setTechStackData={props.setTechStackData}
          setIsOutSideBtnClicked={props.setIsOutSideBtnClicked}
          setChatBtnClickCount={props.setChatBtnClickCount}
          title={"Your Project Types"}
          subTitle={
            "Describe in detail the types of projects you've implemented."
          }
          emptyStackTitle={"Lost in space"}
          emptyStackSubTitle={
            "We don’t have any information about your project types."
          }
          type="categories"
          visibleCls="project"
          searchPlaceholder="Cross Platform"
        />
      );
    } else if (props.devPage === "vacation") {
      display = (
        <Vacation
          firebase={props.firebase}
          vacation={vacations}
          load={vacationsLoading}
          devId={devId}
          devPage={props.devPage}
        />
      );
    } else if (props.devPage === "matches") {
      display = (
        <Matches
          firebase={props.firebase}
          matches={devMatches}
          matchesLoading={matchesLoading}
          setMatchesLoading={setMatchesLoading}
          reComputingMatches={reComputingMatches}
          setReComputingMatches={setReComputingMatches}
          load={props.load}
          devId={devId}
          devPage={props.devPage}
          matchesMetaData={matchesMetaData}
        />
      );
    } else if (props.devPage === "devRates" && !isDev) {
      display = (
        <DevRate
          devRates={devRates}
          devId={devId}
          minDate={minDate}
          firebase={props.firebase}
          devPage={props.devPage}
          maxSecLev={vendorMaxes.maxSecLev}
          hoursInWorkDay={vendor?.settings?.ops?.hoursInWorkDay || 8}
          isVerifiedResource={data?.isVerified}
          devData={data}
          maxRates={vendorMaxes.maxRates}
        />
      );
    } else if (props.devPage === "avail") {
      display = (
        <IndividualAvail
          availability={avails}
          devId={devId}
          firebase={props.firebase}
          holidays={devHolidays}
          devPage={props.devPage}
        />
      );
    }
    // else if (props.devPage === "education") {
    //   display = (
    //     <Education
    //       devPage={props.devPage}
    //       devId={devId}
    //       firebase={props.firebase}
    //       educations={education}
    //     />
    //   );
    // } else if (props.devPage === "exp") {
    //   display = (
    //     <Experience
    //       devPage={props.devPage}
    //       devId={devId}
    //       firebase={props.firebase}
    //       // educations={educations}
    //       experiences={experience}
    //     />
    //   );
    // } else if (props.devPage === "projExp") {
    //   display = (
    //     <ProjectsExperience
    //       devPage={props.devPage}
    //       devId={devId}
    //       firebase={props.firebase}
    //       // educations={projExp}
    //       experiences={projectExp}
    //     />
    //   );
    // }
    return display;
  };
  return (
    <div className="control-pane resource">
      <div className={"devInfo"}>
        <div className="devInfo_grids">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="devInfo-grids-row">{Visual()}</div>
          )}
        </div>
      </div>
    </div>
  );
  // console.log(previousPath);
  // const handleBackClick = () => {
  //   if (previousPath === "/all-resources") {
  //     router("/all-resources");
  //   } else {
  //     router("/resources");
  //   }
  // };
  // return (
  //   <div className="_spDevPage" ref={ref}>
  //     {/* <div className="_spDevPage--content">
  //       <div className="_spDevPage--left-backAction">
  //         <button
  //           className="_spDevPage--left-backAction_btn"
  //           onClick={handleBackClick}
  //         >
  //           <ArrowBackIosIcon />
  //           Back to all resources
  //         </button>
  //       </div>
  //     </div> */}
  //     <div className="_spDevPage--content">
  //       <LeftSection
  //         devData={data}
  //         devPublic={devPublic}
  //         vendorPublic={vendorPublic}
  //         vendor={vendor}
  //         devId={devId}
  //         firebase={props.firebase}
  //       />
  //       <RightSection
  //         devData={data}
  //         devPublic={devPublic}
  //         vendorPublic={vendorPublic}
  //         vendor={vendor}
  //         devId={devId}
  //         onAnswerProfileQuestions={onAnswerProfileQuestions}
  //         assessments={assessments}
  //         assementClicked={assementClicked}
  //         assessementLoading={assessementLoading}
  //         educations={education}
  //         workExperiences={experience}
  //         projectExperiences={projectExp}
  //         handleDevDetailUpdate={handleDevDetailUpdate}
  //         stackExp={stacks}
  //         domainExp={domains}
  //         projExp={projects}
  //         firebase={props.firebase}
  //         allPositions={globalData?.orgPositions}
  //       />
  //     </div>
  //   </div>
  // );
};

export default MainDev;
