import { useCallback, useEffect, useState } from "react";
import TechsCarousel from "../Developer/Carousels/Techs/TechsCarousel";
import AddNew from "./components/AddNew";

const StacksCollection = (props) => {
  const [selectedData, setSelectedData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const defaultStack = props?.stacks[0] ?? {};
  const [assessments, setAssessments] = useState([]);

  // useEffect(() => {
  //   setSelectedData(setSelectedData ?? props?.stacks?.[0] ?? {});
  // }, [props.stacks]);

  useEffect(() => {
    const fetchAssessmentNames = async () => {
      const data = selectedData ?? props?.stacks?.[0] ?? {};
      // if (assessments?.length >= 1) return;
      if (data?.assessments && data.assessments.length > 0) {
        const fetchedAssessments = await Promise.all(
          data.assessments.map(async (assessment) => {
            // console.log("assessment", assessment);
            const doc = await (assessment?.ref ?? assessment?.source)?.get();
            return {
              ref: assessment?.ref,
              id: assessment?.ref?.id,
              rank: assessment?.rank,
              data: { ...doc.data() },
            }; // Assuming 'name' is the property to display
          })
        );
        setAssessments(fetchedAssessments);
      } else {
        // setAssessmentNames([]); // Reset if no assessments
        setAssessments([]);
      }
    };

    fetchAssessmentNames();
  }, [selectedData, props.stacks]);

  // console.log("selectedData", selectedData);

  /**
   * takes the id of the input and the event
   * of the click then opens the file uploads
   * @param {event} e button event
   * @param {string} id input id
   */
  const handleFileUpload = (e, id) => {
    const uploadBtn = document.getElementById(id);
    uploadBtn.click();
  };

  // console.log("selectedData", selectedData);

  const onClose = useCallback(() => {
    setOpenDialog(false);
    setIsEdit(false);
  }, [setOpenDialog, setIsEdit]);

  return (
    <div className="colManagement_body-stacks collection">
      <AddNew
        open={openDialog || isEdit}
        onClose={onClose}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        techsCategories={props.techsCategories}
        firebase={props.firebase}
        handleFileUpload={handleFileUpload}
        collection="techStacks150r"
        isEdit={isEdit}
        categoryId={selectedData?.catId ?? defaultStack.catId}
        setIsEdit={setIsEdit}
        defaultData={
          selectedData?.name
            ? { ...selectedData, assessments }
            : { ...defaultStack, assessments }
        }
        isTech={true}
        setSelectedStacks={setSelectedData}
        searchAssessments={props.searchAssessments}
        searchData={props.searchData}
        setSearchData={props.setSearchData}
        showDropdown={props.showDropdown}
        setShowDropdown={props.setShowDropdown}
        setSelectedAssessmentNames={props.setSelectedAssessmentNames}
        selectedAssessmentNames={props.selectedAssessmentNames}
        onToggleAssessment={props.handleToggleAssessments}
        refreshData={props.refreshData}
        setAssessments={setAssessments}
      />

      <div className="collection_header">
        <h3>STACKS</h3>
        <p>All Sprytelabs Stacks.</p>
      </div>
      <div className="row collection_data">
        <div className="collection_data-grid">
          <TechsCarousel
            showHiddenCard={false}
            stacks={props.stacks}
            firebase={props.firebase}
            numOfStackToShow={48}
            setSelectedStacks={setSelectedData}
            useExist={false}
            onStacksPaginate={props.onStacksPaginate}
          />
          <div>
            <button
              className="spbtn spbtn-sub"
              onClick={() => setOpenDialog(true)}
            >
              Add new Stack
            </button>
          </div>
        </div>
        <div className="collection_data-information" id="collect_edit">
          <div className="collection_data-information__header row">
            <h3>{selectedData?.name ?? defaultStack?.name}</h3>
            <div className="spbtn">
              <button
                id="stacklogoEditBtn"
                className="spbtn-sub spbtn"
                onClick={(e) => setIsEdit(true)}
              >
                Edit
              </button>
            </div>
          </div>

          <div className="collection_data-information__detail">
            <div className="collectDetail">
              <span className="collectDetail_title">name</span>
              <p>{selectedData?.name ?? defaultStack?.name}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Description</span>
              <p>{selectedData?.description ?? defaultStack.description}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">searchKeywords</span>
              <p>
                {selectedData?.searchKeywords ?? defaultStack.searchKeywords}
              </p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Rank</span>
              <p>{selectedData?.rank ?? defaultStack.rank}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Category</span>
              <span>
                {selectedData?.catData?.name ?? defaultStack?.catData?.name}
              </span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">SubCategory</span>
              <p>{selectedData?.subcategory ?? defaultStack.subcategory}</p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Id</span>
              <span>{selectedData?.id ?? defaultStack.id}</span>
            </div>
            <div className="collectDetail">
              <span className="collectDetail_title">Path</span>
              <span>{selectedData?.ref?.path ?? defaultStack?.ref?.path}</span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Show</span>
              <p>
                {selectedData?.show ?? defaultStack.show ? "true" : "false"}
              </p>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">LogoStorage</span>
              <div>
                <span>
                  {selectedData?.logoStorage ?? defaultStack.logoStorage}
                </span>
              </div>
            </div>
            <div className="collectDetail">
              {assessments?.length > 0 && (
                <>
                  <span className="collectDetail_title">Assessments</span>
                  <div>
                    <span>
                      {assessments
                        .map((assessment) => assessment?.data?.name)
                        .join(", ")}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StacksCollection;
