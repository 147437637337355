import { fire } from "./services";

export const dateString = () => {
  //the fulldate should be 2021/01/31
  let currentDate = new Date();
  let day = currentDate.getDate();
  let theDay = day > 9 ? day.toString() : "0" + day.toString();
  let month = currentDate.getMonth() + 1;
  let theMonth = month > 9 ? month.toString() : "0" + month.toString();
  let year = currentDate.getFullYear();
  let fulldate = year.toString() + theMonth + theDay;
  return fulldate;
};

// Create random string of length "length"
export const randString = (length) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const randomPassword = () => {
  let password = "";
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const charsLength = chars.length;
  const passwordLength = 8;

  const array = new Uint32Array(charsLength); // create 'unsigned' array
  window.crypto.getRandomValues(array); // assign random values to new array

  for (let i = 0; i < passwordLength; i++) {
    // adding values to password var from chars using the random values
    password += chars[array[i] % charsLength]; // since the random value is quite, we use % operator which returns remainder of division
  }
  return password;
};

/**
 * check if an email is valid
 * @example
 * ```
 * console.log(validateEmail("email@test.com")) => // returns array of strings if valid
 * console.log(validateEmail(".email@test.com")) => // return null if invalid
 * ```
 * @param {string} email
 * @returns array
 */
export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const isValidUrl = (urlString) => {
  let urlPattern = new RegExp(
    "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}$([-\\w\\+\\.~#\\?&=%]*)?$",
    "i"
  );
  return !!urlPattern.test(urlString);
};

export const getAllAsync = async (collectionRef, datasetter, limit = 10) => {
  let lastItem;
  let docs = [];
  let snap = await collectionRef.limit(limit).get();
  await datasetter(snap.docs);
  docs = snap.docs;
  lastItem = snap.docs[snap.docs.length - 1];
  if (collectionRef)
    while (docs.length > 0) {
      try {
        let dataDocs = await collectionRef
          .startAfter(lastItem)
          .limit(limit)
          .get();
        lastItem = dataDocs.docs[snap.docs.length - 1];

        await datasetter(dataDocs.docs);
        docs = dataDocs.docs;
      } catch (error) {
        return;
      }
    }

  return "my data";
};

export const DEVSCOLLECTIONS = {
  stacks: "stacks",
  domains: "sectors",
  projects: "categories",
  rates: "rateRanges",
  vacation: "vacation",
  availability: "availability",
  devPublic: "devPublic", //for resource open data to be accessible for non logged in users
  experience: "workExp",
  education: "education",
  projExp: "projectExp",
};

export const monthNamesLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const dayNamesLong = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getDayName = (date) => {
  // get day of the month and year
  if (!date) return;
  const day = date?.getDay();
  const dayName = dayNames[day];
  return dayName;
};

/**
 * Check if a value can be converted to a valid date.
 *
 * This function attempts to convert a provided value to a date.
 * If the value is a Firebase Timestamp, it tries to use the toDate() method.
 * If the value is a string or number, it tries to use the Date constructor.
 * If the value cannot be converted to a valid date, it returns false.
 *
 * @param {Object|string|number} dateInput - A Firebase Timestamp, string, or number.
 * @return {boolean} - Returns true if the value can be converted to a valid date, false otherwise.
 *
 * @example
 * var timestamp = firebase.firestore.Timestamp.now();
 * console.log(isValidDate(timestamp)); // should return true if the timestamp is valid
 *
 * @example
 * console.log(isValidDate("2023-05-18")); // should return true
 * console.log(isValidDate("2023-02-29")); // should return false (2023 is not a leap year)
 *
 * @example
 * console.log(isValidDate(1642492800000)); // should return true, it's a valid timestamp
 * console.log(isValidDate(99999999999999999)); // should return false, timestamp is too large
 */
export const isValidDate = (dateInput) => {
  var date;

  // Check if dateInput is a Firebase Timestamp
  if (dateInput instanceof fire.Timestamp) {
    try {
      date = dateInput.toDate();
    } catch (e) {
      // If toDate() throws an error, the timestamp is not valid
      return false;
    }
  } else if (typeof dateInput === "string" || typeof dateInput === "number") {
    // Try to convert the string or number to a Date
    date = new Date(dateInput);
  } else {
    // If dateInput is not a Firebase Timestamp, string, or number, it's not a valid date
    return false;
  }

  // Check if the date is valid
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date.getTime())) {
      // date is not valid
      return false;
    } else {
      // date is valid
      return true;
    }
  } else {
    // not a date
    return false;
  }
};

/**
 * this function takes a date and returns a string in the format of "July 28th 2023"
 * @param {*} date
 * @returns  string
 * @example Monday
 *
 * @example Tuesday
 * */
export const getDayNameLong = (date) => {
  // get day of the month and year

  // check if date is valid

  if (!date) return;
  const day = date?.getDay();
  const dayName = dayNamesLong[day];
  return dayName;
};

export const getMonthName = (date) => {
  // get day of the month and year
  if (!date) return;
  const month = date?.getMonth();
  const monthName = monthNames[month];
  return monthName;
};

export const getMonthNameLong = (date) => {
  // get day of the month and year
  if (!date) return;
  const month = date?.getMonth();
  const monthName = monthNamesLong[month];
  return monthName;
};

export const getDay = (date) => {
  // get day of the month and year
  if (!date) return;
  const theDate = date?.getDate();
  return theDate;
};

export const getYear = (date) => {
  // get day of the month and year
  if (!date) return;
  const year = date?.getFullYear();
  return year;
};

export const getHours = (date) => {
  // get day of the month and year
  if (!date) return;
  const hours = date?.getHours();
  return hours;
};

export const getMinutes = (date) => {
  // get day of the month and year
  if (!date) return;
  const minutes = date?.getMinutes();
  return minutes;
};

export const getSeconds = (date) => {
  // get day of the month and year
  if (!date) return;
  const seconds = date?.getSeconds();
  return seconds;
};

export const getMonth = (date) => {
  // get day of the month and year
  if (!date) return;
  const month = date?.getMonth();
  return month;
};

export const getMonthDayYear = (date) => {
  // get day of the month and year
  if (!date) return;
  const theDate = date?.getDate();
  const month = date?.getMonth();
  const year = date?.getFullYear();
  const monthName = monthNames[month];
  const yearString = year.toString();
  const dateString = `${monthName} ${theDate}, ${yearString}`;
  return dateString;
};

// convert date to string format wed Nov 24/22
export const convertDateToString = (date) => {
  // get day of the month and year
  if (!date) return;
  const theDate = date?.getDate();
  const month = date?.getMonth();
  const year = date?.getFullYear();

  const monthName = monthNames[month];

  const yearString = year.toString();
  //   const hoursString = hours.toString();
  //   const minutesString = minutes.toString();
  const dateString = `${monthName} ${theDate}/${yearString}`;
  return dateString;
};

// convert date to string format wed 01/24/2022
export const convertDateToString2 = (date) => {
  // get day of the month and year
  if (!date) return;
  let theDate = date?.getDate();
  let month = date?.getMonth();
  const year = date?.getFullYear();
  month = month < 10 ? `0${month}` : month;
  theDate = theDate < 10 ? `0${theDate}` : theDate;

  const yearString = year.toString();

  const dateString = `${month}/${theDate}/${yearString}`;
  return dateString;
};

export const createSlug = (title) => {
  var slug = title.toLowerCase();
  slug = slug.replace(/[^a-z0-9]+/g, "-");
  slug = slug.replace(/^-+|-+$/g, "");
  return slug;
};

/**
 * this function takes a date and returns a string in the format of "July 28th 2023"
 * @param {*} date
 * @returns  string
 */
export const renderDMYLong = (date) => {
  // return date in format July 28th 2023
  if (!date) return;
  const theDate = date?.getDate();
  const month = date?.getMonth();
  const year = date?.getFullYear();
  const monthName = monthNamesLong[month];
  const yearString = year.toString();
  const dateString = `${monthName} ${theDate} ${yearString}`;
  return dateString;
};

/**
 * this function takes a date and returns a string in the for
 * @param {*} date
 * @returns  string
 * @example 2 days ago
 * @example 1 day ago
 * */
export const renderDaysAgo = (date) => {
  // return date in format 2 days ago or 1 day ago
  if (!date) return;
  const today = new Date();
  const diffTime = Math.abs(today - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 1) {
    return "1 day ago";
  }
  return `${diffDays} days ago`;
};

export const renderTimeAgo = (date) => {
  if (!date) return;

  const today = new Date();
  const diffTime = Math.abs(today - date);
  const diffSeconds = Math.floor(diffTime / 1000);

  if (diffSeconds < 60) {
    return `${diffSeconds} sec ago`;
  }

  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  if (diffMinutes < 60) {
    return `${diffMinutes} min ago`;
  }

  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  if (diffHours < 24) {
    return `${diffHours} hrs ago`;
  }

  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 1) {
    return "1 day ago";
  }

  return `${diffDays} days ago`;
};

export const renderYearsDiffFRomYear = (n) => {
  if (!n) return;
  const today = new Date();
  const year = today.getFullYear();
  const diff = year - n;
  return diff;
};

export const renderYearsDiffFRomDate = (date) => {
  if (!date) return;
  const today = new Date();
  const year = today.getFullYear();
  const diff = year - date.getFullYear();
  return diff;
};

export const renderStepperBullets = (list) => {
  return list?.map((item, index) => {
    return (
      <span style={{ display: "block" }} key={index}>
        {item}
      </span>
    );
  });
};

/**
 * Converts a normalized value (0 to 1) to a score based on a specified maximum score.
 *
 * @param {number} normalizedValue - The normalized value representing a percentage (between 0 and 1).
 * @param {number} maxScore - The maximum score to scale to.
 * @return {number} The score out of the maximum score.
 * Example Usage:
 *  - calculateScoreFromPercentile(0.34, 5) returns 1.7
 *  - calculateScoreFromPercentile(0.87, 5) returns 4.35
 */
export const calculateScoreFromPercentile = (normalizedValue, maxScore = 5) => {
  if (normalizedValue < 0 || normalizedValue > 1) {
    console.error("Normalized value must be between 0 and 1.");
    return 0; // Return 0 if the input is out of expected range
  }

  // Calculate the score based on the normalized value
  const score = normalizedValue * maxScore;

  // Optionally, you might want to round the score to a certain number of decimal places
  // For example, to round to two decimal places:
  // return Math.round(score * 100) / 100;

  return score;
};

/**
 * Converts a score to a normalized value between 0 and 1.
 *
 * @param {number} currentScore - The current score achieved.
 * @param {number} maxScore - The maximum possible score.
 * @return {number} The normalized score between 0 and 1.
 *  Example Usage:
 * console.log(normalizeScore(2, 5));  // Output: 0.4
 * console.log(normalizeScore(3.5, 5)); // Output: 0.7
 */
export const normalizeScore = (currentScore, maxScore = 5) => {
  if (maxScore === 0) {
    console.error("Maximum score cannot be zero.");
    return 0; // Avoid division by zero
  }

  // Calculate normalized score
  let normalized = currentScore / maxScore;

  // Ensuring the score doesn't exceed 1 or fall below 0 due to any unexpected inputs
  if (normalized > 1) {
    console.warn(
      "Current score is greater than the maximum score. Normalizing to 1."
    );
    normalized = 1;
  } else if (normalized < 0) {
    console.warn("Current score is less than zero. Normalizing to 0.");
    normalized = 0;
  }

  return normalized;
};

export const convertToDate = (date) => {
  if (!date) return null;
  if (date.toDate) return date.toDate();
  return new Date(date);
};

/**
 * Limits a number to a maximum of two digits.
 * If the number is a whole number with at most two digits, it returns the number as is.
 * If the number has decimal places, it rounds it to the nearest whole number if it's less than 100.
 * If rounding results in a number 100 or greater, it truncates the number to two decimal places.
 *
 * @param {number} number - The input number to be limited.
 * @returns {number} - The limited number with a maximum of two digits.
 */
export const limitToTwoDigits = (number) => {
  // Round the number to the nearest whole number
  let roundedNumber = Math.round(number);

  // If the rounded number is less than 100, return it
  if (roundedNumber < 100) {
    return roundedNumber;
  }

  // Truncate the number to two decimal places
  let truncatedNumber = parseFloat(number.toFixed(2));

  // If the truncated number is less than 100, return it
  if (truncatedNumber < 100) {
    return truncatedNumber;
  }

  // Return the number truncated to two decimal places
  return truncatedNumber;
};

/**
 * Sets the value at a nested property of an object given a string path.
 * @param {Object} obj - The object to update.
 * @param {String} path - The string path representing the nested property (e.g., "leadTime.leadTimeOn").
 * @param {*} value - The value to set at the specified nested property.
 */
export const setNestedProperty = (obj, path, value) => {
  const keys = path.split(".");
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!(key in current)) {
      current[key] = {};
    }
    current = current[key];
  }

  current[keys[keys.length - 1]] = value;
};

export const generateTimestamp = () => {
  const now = new Date();
  return now.toISOString().replace(/[-:.]/g, ""); // Format the timestamp
};

/**
 * Recursively compare two objects for deep equality.
 *
 * @param {Object} obj1 - The first object.
 * @param {Object} obj2 - The second object.
 * @param {Map} [seen] - Map to track already compared objects/arrays to handle circular references.
 * @param {number} [depth=10] - Depth to handle deep nesting.
 * @returns {boolean} - Whether the two objects are deeply equal.
 */
export const objectsAreEqual = (obj1, obj2, seen = new Map(), depth = 10) => {
  if (!obj1 || !obj2) return obj1 === obj2; // Return true if both are null/undefined or false otherwise.

  if (depth <= 0) return true;

  if (seen.has(obj1)) return seen.get(obj1) === obj2;
  if (seen.has(obj2)) return seen.get(obj2) === obj1;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  seen.set(obj1, obj2);
  seen.set(obj2, obj1);

  for (let key of keys1) {
    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      if (!objectsAreEqual(obj1[key], obj2[key], seen, depth - 1)) return false;
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

/**
 * Recursively compare two arrays for deep equality.
 *
 * @param {Array} arr1 - The first array.
 * @param {Array} arr2 - The second array.
 * @param {Map} [seen] - Map to track already compared objects/arrays to handle circular references.
 * @returns {boolean} - Whether the two arrays are deeply equal.
 */
export const arraysAreEqual = (arr1, arr2, seen = new Map()) => {
  if (!arr1 || !arr2) return false; // Guard clause to check for undefined arrays.
  if (arr1.length !== arr2.length) return false;

  // Check if we've already compared these arrays
  if (seen.has(arr1)) return seen.get(arr1) === arr2;
  if (seen.has(arr2)) return seen.get(arr2) === arr1;

  seen.set(arr1, arr2);
  seen.set(arr2, arr1);

  for (let i = 0; i < arr1?.length; i++) {
    if (typeof arr1[i] === "object" && typeof arr2[i] === "object") {
      if (!objectsAreEqual(arr1[i], arr2[i], seen)) return false;
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
