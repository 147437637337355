import { HighlightOff } from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";
import { db } from "../../../globals/services";
import Select from "react-select";
import SwitchSp from "../../../globals/MuiExtends/SwitchSp";
import AssessmentSelector from "./AssessmentSelector";

const NewStack = (props) => {
  return (
    <div>
      <div className="sp-form_item">
        <label>Name</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              name: e.target.value,
            })
          }
          value={props.techData.name}
        />
      </div>
      <div className="sp-form_item">
        <label>Search keyword</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              searchKeywords: e.target.value,
            })
          }
          value={props.techData.searchKeywords}
        />
      </div>
      <div className="row uploader spbtn">
        <div>
          <input
            type="file"
            id="stackLogo"
            onChange={(e) => props.selectLogo(e)}
            accept=".png, .jpg, .jpeg"
            hidden
          />
          <button
            id="stacklogoBtn"
            className="btn spbtn-sub"
            onClick={(e) => props.handleOpen(e, "stackLogo")}
          >
            Upload Stack Logo
          </button>
        </div>
        <div className="uploader-preview">
          <img
            className="inputs-row_main-preview__img"
            src={props.logo.url ?? props.techData.logoStorage}
            alt={props.logo?.file?.name}
          />
          {props.logo.url ? (
            <HighlightOff
              onClick={() => {
                props.setLogo({});
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="sp-form_item">
        <label>Rank</label>
        <input
          type="number"
          className="sp-form_item--inp sp-form_item--inp_btm"
          min={0}
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              rank: parseInt(e.target.value),
            })
          }
          value={props.techData.rank}
        />
      </div>
      {props.openDialog ? (
        <div className="sp-form_item">
          <label>Category</label>
          <Select
            options={props.techsCategories}
            className="basic-multi-select"
            classNamePrefix="select stackName"
            // isMulti
            name="colors"
            // defaultValue={props.techData.category}
            onChange={(e) =>
              props.setTechData({
                ...props.techData,
                techCategory: e,
              })
            }
          />
        </div>
      ) : (
        <></>
      )}
      {/* {props.isEdit ? ( */}
      <div className="sp-form_item">
        <label for="status">Show</label>
        {/* <Select
            options={props.show}
            className="basic-multi-select"
            classNamePrefix="select stackName"
            // isMulti
            name="colors"
            defaultValue={{
              value: props.techData.show,
              label: `${props.techData.show}`,
            }}
            onChange={(e) =>
              props.setTechData({
                ...props.techData,
                show: e,
              })
            }
          /> */}
        <select
          name="status"
          id="status"
          onChange={(e) => {
            let status = e.target.value === "true" ? true : false;
            props.setTechData({
              ...props.techData,
              show: status,
            });
            e.persist();
          }}
        >
          <option value={true} selected={props.techData.show ? true : false}>
            True
          </option>
          <option value={false} selected={!props.techData.show ? true : false}>
            False
          </option>
        </select>
      </div>
      {/* ) : (
        <>:</>
      )} */}

      <div className="sp-form_item">
        <label>Sub Category</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              subcategory: e.target.value,
            })
          }
          value={props.techData.subcategory}
        />
      </div>

      <div className="sp-form_item">
        <label>Description</label>
        <textarea
          className="sp-form_item--txtarea sp-form_item--txtarea_btm"
          onChange={(e) =>
            props.setTechData({
              ...props.techData,
              description: e.target.value,
            })
          }
          value={props.techData.description}
        ></textarea>
      </div>
      <AssessmentSelector
        searchAssessments={props.searchAssessments}
        searchData={props.searchData}
        setSearchData={props.setSearchData}
        showDropdown={props.showDropdown}
        setShowDropdown={props.setShowDropdown}
        selectedAssessmentNames={props.selectedAssessmentNames}
        onToggleAssessment={props.onToggleAssessment}
        toggleAssessment={props.toggleAssessment}
        onRemoveAssessment={props.onRemoveAssessment}
        selectedAssessments={props.selectedAssessments}
      />
    </div>
  );
};
export default NewStack;
