import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SwitchSp from "../../../globals/MuiExtends/SwitchSp";

const Wrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  ${(props) =>
    props.isAbove ? `bottom: calc(100% - 10px);` : `top: calc(100% - 10px);`}
`;

const SelectedTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Tag = styled.span`
  background-color: #f0f0f0;
  color: #333;
  padding: 6px 10px;
  // border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  // border: 1px solid #ccc;
  font-size: 14px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const CloseIcon = styled.span`
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

const AssessmentSelector = ({
  searchAssessments,
  searchData,
  setSearchData,
  showDropdown,
  setShowDropdown,
  selectedAssessmentNames,
  toggleAssessment,
  onRemoveAssessment,
  selectedAssessments,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedAssessmentNames, setSelectedAssessmentNames] = useState([]);
  const [isAbove, setIsAbove] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!searchTerm.trim()) {
      setShowDropdown(false);
      setSearchData([]);
      return;
    }

    searchAssessments(searchTerm);
  }, [searchTerm]);

  const handleToggle = (assessment) => {
    toggleAssessment(assessment);
  };

  const selectAssessment = (assessment) => {
    setSearchTerm(assessment?.data?.name);
    setShowDropdown(false);
    handleToggle(assessment); // Toggle the assessment
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const adjustDropdownPosition = () => {
    if (inputRef.current && dropdownRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const viewportHeight = window.innerHeight;

      setIsAbove(inputRect.bottom + dropdownHeight > viewportHeight);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      adjustDropdownPosition();
    }
  }, [showDropdown, searchData]);

  const handleRemoveTag = (assessment) => {
    onRemoveAssessment(assessment); // Delegate to parent logic
  };

  return (
    <Wrapper>
      <InputWrapper>
        <label>Search and select assessment</label>
        <input
          type="text"
          className="sp-form_item--inp sp-form_item--inp_btm"
          placeholder="Search..."
          value={searchTerm}
          ref={inputRef}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setShowDropdown(true)}
        />
        {showDropdown && (
          <DropdownContainer ref={dropdownRef} isAbove={isAbove}>
            {searchData.length > 0 ? (
              searchData.map((assessment) => {
                // console.log(assessment);
                return (
                  <div
                    key={assessment.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // padding: "8px 0",
                      alignItems: "center",
                    }}
                  >
                    <p onClick={() => selectAssessment(assessment)}>
                      {assessment.data.name}
                    </p>
                    <SwitchSp
                      onChange={() => handleToggle(assessment)}
                      checked={selectedAssessments.some(
                        (selected) => selected.ref.path === assessment.ref.path
                      )}
                    />
                  </div>
                );
              })
            ) : (
              <p>Not found</p>
            )}
          </DropdownContainer>
        )}
      </InputWrapper>
      {selectedAssessments.length > 0 && (
        <div>
          <label>Selected assessments</label>
          <SelectedTagsContainer>
            {selectedAssessments.map((assessment) => (
              <Tag key={assessment.ref.path}>
                {assessment?.data?.name}
                <CloseIcon onClick={() => handleRemoveTag(assessment)}>
                  ×
                </CloseIcon>
              </Tag>
            ))}
          </SelectedTagsContainer>
        </div>
      )}
    </Wrapper>
  );
};

export default AssessmentSelector;
