import { useCallback, useEffect, useState } from "react";
import ProjectsCarousel from "../Developer/Carousels/Projects/ProjectsCarousel";
import AddNew from "./components/AddNew";

const ProjectsCollection = (props) => {
  const [selectedData, setSelectedData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const defaultStack = props?.projects[0] ?? {};
  const [assessments, setAssessments] = useState([]);

  const categoryId =
    props?.categoriesId?.[selectedData?.category] ??
    props?.categoriesId?.[defaultStack?.category];

  useEffect(() => {
    if (!selectedData) {
      setSelectedData(props.projects[0]);
    }
  }, [props.projects]);

  /**
   * takes the id of the input and the event
   * of the click then opens the file uploads
   * @param {event} e button event
   * @param {string} id input id
   */
  const handleFileUpload = (e, id) => {
    const uploadBtn = document.getElementById(id);
    uploadBtn.click();
    // e.prevendDefault();
  };

  const onClose = useCallback(() => {
    setOpenDialog(false);
    setIsEdit(false);
  }, [setOpenDialog, setIsEdit]);

  useEffect(() => {
    const fetchAssessmentNames = async () => {
      const data = selectedData ?? props?.projects?.[0] ?? {};
      // console.log("data", data);
      // if (assessments?.length >= 1) return;
      if (data?.assessments && data.assessments.length > 0) {
        const fetchedAssessments = await Promise.all(
          data.assessments.map(async (assessment) => {
            const doc = await assessment.ref.get();
            return {
              ref: assessment.ref,
              id: assessment.ref.id,
              rank: assessment.rank,
              data: { ...doc.data() },
            }; // Assuming 'name' is the property to display
          })
        );
        setAssessments(fetchedAssessments);
      } else {
        // setAssessmentNames([]); // Reset if no assessments
        setAssessments([]);
      }
    };

    fetchAssessmentNames();
  }, [selectedData, props?.projects]);

  return (
    <div className="colManagement_body-stacks collection">
      <AddNew
        open={openDialog || isEdit}
        onClose={onClose}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        techsCategories={props.domainCategories}
        firebase={props.firebase}
        handleFileUpload={handleFileUpload}
        setIsUpdated={props.setIsUpdated}
        collection="categories"
        isEdit={isEdit}
        categoryId={categoryId}
        setIsEdit={setIsEdit}
        defaultData={{ ...selectedData, assessments }}
        refreshData={props.refreshData}
        // isTech={true}
        searchAssessments={props.searchAssessments}
        searchData={props.searchData}
        setSearchData={props.setSearchData}
        showDropdown={props.showDropdown}
        setShowDropdown={props.setShowDropdown}
        setSelectedAssessmentNames={props.setSelectedAssessmentNames}
        selectedAssessmentNames={props.selectedAssessmentNames}
        onToggleAssessment={props.handleToggleAssessments}
        setAssessments={setAssessments}
      />

      <div className="collection_header">
        <h3>CATEGORIES</h3>
        <p>All Sprytelabs Categories.</p>
      </div>
      <div className="row collection_data">
        <div className="collection_data-grid">
          <ProjectsCarousel
            showHiddenCard={false}
            projects={props.projects}
            firebase={props.firebase}
            numOfDomToShow={12}
            setSelectedData={setSelectedData}
            useExist={false}
            onCategoriesPaginate={props.onCategoriesPaginate}
          />
          <div>
            <button
              className="spbtn spbtn-sub"
              onClick={() => setOpenDialog(true)}
            >
              Add New Category
            </button>
          </div>
        </div>
        <div className="collection_data-information" id="collect_edit_projects">
          <div className="collection_data-information__header row">
            <h3>
              Edit {selectedData?.name ?? defaultStack.name} Industry Domain
            </h3>
            <div className="spbtn">
              <button
                id="stacklogoEditBtn"
                className="spbtn-sub spbtn"
                onClick={(e) => setIsEdit(true)}
              >
                Edit
              </button>
            </div>
          </div>

          <div className="collection_data-information__detail">
            {/* <div className="collectDetail">
              <span>CatIndex</span>
              <span>...</span>
            </div> */}

            <div className="collectDetail">
              <span className="collectDetail_title">name</span>
              <span>{selectedData?.name ?? defaultStack.name}</span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Description</span>
              <span>
                {selectedData?.description ?? defaultStack.description}
              </span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">searchKeywords</span>
              <span>
                {selectedData?.searchKeywords ?? defaultStack.searchKeywords}
              </span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Category</span>
              <span>
                {selectedData?.catData?.name ?? defaultStack?.catData?.name}
              </span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Id</span>
              <span>{selectedData?.id ?? defaultStack.id}</span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Path</span>
              <span>{selectedData?.ref?.path ?? defaultStack?.ref?.path}</span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">Show</span>
              <span>
                {selectedData?.show ?? defaultStack.show ? "true" : "false"}
              </span>
            </div>

            <div className="collectDetail">
              <span className="collectDetail_title">LogoStorage</span>
              <div>
                <span>
                  {selectedData?.logoStorage ?? defaultStack.logoStorage}
                </span>
              </div>
            </div>
            <div className="collectDetail">
              {assessments?.length > 0 && (
                <>
                  <span className="collectDetail_title">Assessments</span>
                  <div>
                    <span>
                      {assessments
                        .map((assessment) => assessment?.data?.name)
                        .join(", ")}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectsCollection;
