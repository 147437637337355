import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "../css/DevRegist.css";
import ErrorPage from "./ErrorPage";
import { db } from "./globals/services";

function PartnerDevSignUp(props) {
  const { orgId, id } = useParams();
  const [searchParams] = useSearchParams();
  const lId = searchParams.get("lId");
  const cId = searchParams.get("cId");
  const tucId = searchParams.get("tucId");
  const pos = searchParams?.get("pos") ?? 0;
  // Check if all required parameters exist
  const hasAllParams = lId && cId && tucId && pos;
  // console.log({ lId, cId, tucId, pos });

  const navigate = useNavigate();
  const [vendor, setVendor] = useState(null);
  const [step, setStep] = useState(1);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [location, setLocation] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [error, setError] = useState(""); // use this to display error to user while registration
  const [loading, setLoading] = useState(false);
  const [gitLink, setGitLink] = useState("");
  const [linkd, setLinkd] = useState("");
  const [position, setPosition] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [positions, setPositions] = useState([]);
  const [orgRef, setOrgRef] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    props?.authUser?.onUpddatState("isDevSelfReg", true);
    const getVendor = async () => {
      props.setIsPartnerDevs(true);
      setIsLoading(true);

      const vendorDoc = await props.firebase.db
        .collection(`vendors/${id}/partnerPublic`)
        .doc("profile")
        .get();
      if (vendorDoc.exists) {
        let vendor = vendorDoc.data();
        setVendor(vendor);
      }

      let positionDocs = await props.firebase.db
        .collection(`organisations/${orgId}/positions`)
        .get();
      if (!positionDocs.empty) {
        let positions = [];
        positionDocs.docs.forEach((posDoc) => {
          let position = posDoc.data();
          if (!orgRef) {
            const ref = posDoc.ref.parent.parent;
            setOrgRef(ref);
          }
          position["ref"] = posDoc.ref;
          positions.push(position);
        });
        setPositions(positions);
      }
      setIsLoading(false);
    };
    getVendor();
    return () => {
      props.setIsPartnerDevs(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orgId]);

  // create default connections with highest rank acc manager & highest rank success manager on successful registration
  const createDefaultConnections = async (currentUserObj) => {
    try {
      // retrieve and sort the ACCOUNT managers
      const accManagers = currentUserObj?.managers?.ACCOUNT || [];
      const highestRankAccManager = accManagers
        .sort((a, b) => a.rank - b.rank)
        .pop();
      const highestRankAccManagerRef = highestRankAccManager?.ref;

      // retrieve and sort the SUCCESS managers
      const successManagers = currentUserObj?.managers?.SUCCESS || [];
      const highestRankSuccessManager = successManagers
        .sort((a, b) => a.rank - b.rank)
        .pop();
      const highestRankSuccessManagerRef = highestRankSuccessManager?.ref;

      // create connection data
      const userConnectionCatRef = props.firebase.db.doc(
        "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/user"
      );
      const followerConnectionType = props.firebase.db.doc(
        "/organisations/T6BAcTjwbXleCFpmWTmu/connectionTypes/follower"
      );

      const accManagerConnectData1 = {
        connectedOn: new Date(),
        connectionCat: userConnectionCatRef,
        connectionTypes: [followerConnectionType],
        lastUpdated: new Date(),
        ref: highestRankAccManagerRef,
        note: "",
        reciprocal: false,
      };

      const accManagerConnectData2 = {
        connectedOn: new Date(),
        connectionCat: userConnectionCatRef,
        connectionTypes: [followerConnectionType],
        lastUpdated: new Date(),
        ref: currentUserObj?.ref,
        note: "",
        reciprocal: true,
      };

      const successManagerConnectData1 = {
        connectedOn: new Date(),
        connectionCat: userConnectionCatRef,
        connectionTypes: [followerConnectionType],
        lastUpdated: new Date(),
        ref: highestRankSuccessManagerRef,
        note: "",
        reciprocal: false,
      };

      const successManagerConnectData2 = {
        connectedOn: new Date(),
        connectionCat: userConnectionCatRef,
        connectionTypes: [followerConnectionType],
        lastUpdated: new Date(),
        ref: currentUserObj?.ref,
        note: "",
        reciprocal: true,
      };

      // helper function to check if a connection already exists
      const connectionExists = async (userRef, connectionRef) => {
        const connectionsQuery = await props.firebase.db
          .collection(`${userRef.path}/connections`)
          .where("ref", "==", connectionRef)
          .get();
        return !connectionsQuery.empty;
      };

      // helper function to add connection if it doesn't exist
      const addConnectionIfNotExists = async (userRef, connectionData) => {
        const exists = await connectionExists(userRef, connectionData.ref);
        if (!exists) {
          await props.firebase.db
            .collection(`${userRef.path}/connections`)
            .add(connectionData);
        }
      };

      // create default connections
      // two-way connection with highest rank account manager
      if (highestRankAccManagerRef) {
        await addConnectionIfNotExists(
          currentUserObj?.ref,
          accManagerConnectData1
        );
        await addConnectionIfNotExists(
          highestRankAccManagerRef,
          accManagerConnectData2
        );
      }

      // two-way connection with highest rank success manager
      if (highestRankSuccessManagerRef) {
        await addConnectionIfNotExists(
          currentUserObj?.ref,
          successManagerConnectData1
        );
        await addConnectionIfNotExists(
          highestRankSuccessManagerRef,
          successManagerConnectData2
        );
      }

      console.log("Default connections created successfully.");
    } catch (error) {
      console.error(
        "Error creating default connections in createDefaultConnections function:",
        error
      );
      throw new Error("Failed to create default connections.");
    }
  };

  const checkPass = () => {
    if (password !== confirmationPassword) {
      setError("Password Shoul Match");
      return;
    } else if (password.length < 6) {
      setError("Password Should Be at least 6 characters");
      return;
    }
  };

  const onSubmit = async () => {
    checkPass();

    const requiredFields = {
      first,
      last,
      email,
      password,
      "position label": position?.label,
      location,
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([_, value]) => !value)
      .map(([field]) => field);

    if (missingFields.length > 0 || password !== confirmationPassword) {
      setError(
        `Please fill in all required fields${
          missingFields.length ? ": " + missingFields.join(", ") : ""
        }`
      );
      return;
    }

    try {
      setLoading(true);
      setError("");

      const auth = await props.firebase.doCreateUserWithEmailAndPassword(
        email,
        password
      );

      const refs = {
        vendor: db.doc(`vendors/${id}`),
        devReporting: db.collection("devsReporting").doc(),
        dev: db.collection("devs").doc(),
        user: db.doc(`/users/${auth.user.uid}`),
        rolePartner: db.doc(
          `/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/RESOURCE`
        ),
        roleDev: db.doc(
          `/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/USER`
        ),
      };

      const data = {
        dev: {
          devID: "0",
          first,
          last,
          addedOn: new Date(),
          email,
          position: position.label,
          positionRef: position.ref,
          userRef: refs.user,
          orgRef,
          draft: false,
          linkedIn: {
            profileUrl: linkd,
            profileLastUpdatedOn: new Date(),
          },
          status: false,
          locTag: location,
          crossVendor: false,
          vendorRef: refs.vendor,
          rating: 0,
          intern: props.isIntern,
          featured: false,
          devsReportingId: refs.devReporting,
          isVerified: false,
        },
        reporting: {
          addedOn: new Date(),
          city: location,
          companyName: vendor.companyName,
          companyWebsite: vendor.website,
          currentProjects: null,
          devId: refs.dev,
          geoTag: null,
          firstName: first,
          lastName: last,
          freelancer: false,
          github: gitLink,
          linkedInProfile: linkd,
          position: position.label,
          positionRef: position.ref,
          sprints: [],
          stakeholderOf: [],
        },
        user: {
          first,
          last,
          email,
          status: true,
          addedOn: new Date(),
          orgRef,
        },
        sprytePartnerAccount: {
          role: refs.rolePartner,
          status: true,
          devRef: refs.dev,
        },
        spryteDevAccount: {
          role: refs.roleDev,
          status: true,
          devsReportingId: refs.devReporting,
        },
      };

      await db.runTransaction(async (transaction) => {
        // console.log("Transaction started");

        let canCreateTucEntry = false;
        if (hasAllParams && cId && lId && tucId && pos !== undefined) {
          const tucDocRef = db
            .collection("client")
            .doc(cId)
            .collection("lists")
            .doc(lId)
            .collection("tuc")
            .doc(tucId);

          const tucDoc = await transaction.get(tucDocRef);

          if (tucDoc.exists) {
            const tucData = tucDoc.data();
            canCreateTucEntry =
              tucData.partners?.some(
                (partnerRef) => partnerRef.path === refs.vendor.path
              ) || false;
          }
        }

        transaction.set(refs.user, data.user, { merge: true });
        transaction.set(refs.dev, data.dev, { merge: true });
        transaction.set(refs.devReporting, data.reporting, { merge: true });

        const accountRefs = {
          sprytePartner: db
            .collection(`/users/${auth.user.uid}/accounts`)
            .doc("spryte-partner"),
          spryteDev: db
            .collection(`/users/${auth.user.uid}/accounts`)
            .doc("spryte-dev"),
        };

        transaction.set(accountRefs.sprytePartner, data.sprytePartnerAccount, {
          merge: true,
        });
        transaction.set(accountRefs.spryteDev, data.spryteDevAccount, {
          merge: true,
        });

        if (canCreateTucEntry) {
          const tucRefs = {
            pos: db
              .collection("client")
              .doc(cId)
              .collection("lists")
              .doc(lId)
              .collection("tuc")
              .doc(tucId)
              .collection(`POS-${Number(pos) + 1}`)
              .doc(),
            status: db
              .collection("client")
              .doc(cId)
              .collection("lists")
              .doc(lId)
              .collection("resourceStatuses")
              .doc("SELF-CANDIDATURE"),
          };

          transaction.set(
            tucRefs.pos,
            {
              addedOn: new Date(),
              devRef: refs.dev,
              name: `${first} ${last}`,
              notes: [],
              partnerRef: refs.vendor,
              reviews: [],
              status: tucRefs.status,
              tags: [],
            },
            { merge: true }
          );
        }
      });

      if (hasAllParams && cId && lId && tucId && pos !== undefined) {
        const platformUrl = `https://sprytelabs.com/reqCandidates?lId=${lId}&cId=${cId}&pId=${id}&email=${encodeURIComponent(email)}`;
        // console.log("Redirecting to:", platformUrl);
        window.location = platformUrl;
      }

      // console.log("Transaction successfully committed");

      // create default connections on successful user registration
      // setTimeout(async () => {
      //   console.log('Now, getting the newly registered data...');
      //   console.log('Creating default connections for connections for the newly registered usre');
      //   const userSnapshot = await refs.user.get();
      //   const userData = userSnapshot.data();
      //   const currentUserObj = { ...userData, ref: refs.user };
      //   // console.log({ userSnapshot, userData, currentUserObj });

      //   await createDefaultConnections(currentUserObj);

      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error in onSubmit:", error);
      setError(error.message || "An error occurred during submission");
    } finally {
      setLoading(false);
    }
  };

  // console.log("authUser", props.authUser);

  // useEffect(() => {
  //   if (props.authUser?.devRef) {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.authUser?.devRef]);

  const onPositionSelect = (e) => {
    if (parseInt(e.target.value) >= 0) {
      let selectedPosition = positions[parseInt(e.target.value)];
      setPosition(selectedPosition);
    }
    e.persist();
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateSteps = async () => {
    if (step === 1) {
      if (first && last && location) {
        setError("");
        setStep(2);
        setLoading(false);
      } else {
        setError("All fields must not be empty.");
      }
    } else if (step === 2) {
      if (!email) {
        setError("Email is required.");
      } else if (!isValidEmail(email)) {
        setError("Please enter a valid email address.");
      } else if (!Object.keys(position).length) {
        setError("Please select a position.");
      } else {
        setError("");
        setStep(3);
      }
    }
  };

  // Function to check if the current step is valid
  const isStepValid = () => {
    if (step === 1) {
      return first && last && location;
    }
    if (step === 2) {
      return email && isValidEmail(email) && Object.keys(position).length;
    }
    if (step === 3) {
      return (
        password && confirmationPassword && password === confirmationPassword
      );
    }
    return false;
  };
  // console.log({ position });

  return (
    <div>
      {isLoading ? (
        <div className="main">
          <CircularProgress color="primary" size="large" />
        </div>
      ) : (
        <>
          {vendor && positions.length ? (
            <div className="registrationCtr">
              <div className="regLeft">
                <div className="logo">
                  <img src={vendor?.logo?.url} alt={vendor?.logo?.name} />
                  <h3>{vendor?.companyName}</h3>
                </div>
                <h2>
                  {!props.isIntern
                    ? "Resource Registration"
                    : "Internship Registration"}
                </h2>
                {step === 1 ? (
                  <>
                    <label for="first">Your First Name</label>
                    <input
                      onChange={(event) => setFirst(event.target.value)}
                      placeholder="Joe "
                      name="first"
                      autocomplete="off"
                      value={first}
                    ></input>
                    <label for="Last">Your Last Name</label>
                    <input
                      onChange={(event) => setLast(event.target.value)}
                      placeholder="Doe"
                      name="Last"
                      value={last}
                    ></input>
                    <label for="location">Your Location</label>
                    <input
                      onChange={(event) => setLocation(event.target.value)}
                      placeholder="New York"
                      name="location"
                      value={location}
                    ></input>
                  </>
                ) : step === 2 ? (
                  <>
                    <label for="position">Your Position</label>
                    <select
                      name="position"
                      id="position"
                      // value={props.managerDetails?.name ?? "no Name"}
                      onChange={(e) => onPositionSelect(e)}
                    >
                      <option value="no">Select a Position</option>
                      {positions.map((position, i) => {
                        return (
                          <option value={i} key={i}>
                            {position.label}
                          </option>
                        );
                      })}
                    </select>
                    <label for="email">Your Email</label>
                    <input
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder="contact@example.com"
                      autocomplete="off"
                      value={email}
                    ></input>
                    <label for="linkd">Your LinkedIn URL</label>
                    <input
                      onChange={(event) => {
                        setLinkd(event.target.value);
                      }}
                      placeholder="https://www.linkedin.com/in/joe-doe/"
                      // autocomplete="(123) 456 7890"
                      value={linkd}
                    ></input>
                    <label for="gitHub">Your GitHub Link</label>
                    <input
                      onChange={(event) => {
                        setGitLink(event.target.value);
                      }}
                      // placeholder="https://www.linkedin.com/in/joe-doe/"
                      // autocomplete="(123) 456 7890"
                      value={gitLink}
                    ></input>
                  </>
                ) : (
                  <>
                    <label for="password">Password</label>
                    <input
                      onChange={(event) => setPassword(event.target.value)}
                      placeholder="Enter your password"
                      value={password}
                      type="password"
                    ></input>
                    <label for="confirmPassword">Confirm Password</label>
                    <input
                      onChange={(event) =>
                        setConfirmationPassword(event.target.value)
                      }
                      placeholder="Confirm Your Password"
                      value={confirmationPassword}
                      type="password"
                    ></input>
                  </>
                )}
                {error !== "" ? <p className="error">{error}</p> : null}
                <div className="rememberCtr">
                  <div style={{ display: "flex" }}>
                    {/* <input type="checkbox" checked></input> */}
                    {/* <p className="remember">
                  By creating an account you agree to the terms of use and our
                  privacy policy.
                </p> */}
                  </div>
                </div>
                <div className="buttonCtr">
                  {step === 2 || step === 3 ? (
                    <div
                      onClick={() => {
                        setStep(step === 2 ? 1 : 2);
                      }}
                      className="backBtn"
                    >
                      <h3>&#8810;</h3>
                    </div>
                  ) : null}
                  {step === 1 || step === 2 ? (
                    <button
                      onClick={() => {
                        validateSteps();
                      }}
                      disabled={!isStepValid()}
                    >
                      Next Step
                    </button>
                  ) : !loading ? (
                    <button
                      onClick={() => {
                        onSubmit();
                      }}
                      disabled={!isStepValid() || loading}
                    >
                      Submit
                    </button>
                  ) : (
                    <button>
                      <div className="spinner-box">
                        <div className="pulse-container small">
                          <div className="pulse-bubble pulse-bubble-1"></div>
                          <div className="pulse-bubble pulse-bubble-2"></div>
                          <div className="pulse-bubble pulse-bubble-3"></div>
                        </div>
                      </div>
                    </button>
                  )}
                </div>
                <p className="signIn">
                  Already have an account?{" "}
                  <span onClick={() => navigate("/")}>Log In</span>
                </p>
                <p className="signIn">
                  Powered By{" "}
                  <a
                    href="https://www.sprytelabs.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>SpryteLabs</span>
                  </a>
                </p>
              </div>
              <div className="regRight">
                <div className="ssCtr">
                  <div>
                    <div className="topSs">
                      <h3>Popular Resources</h3>
                      <h3 className="editBtn">Edit</h3>
                    </div>
                    <div className="topSs">
                      <p>Partner since</p>
                      <p className="date">June 14, 2018</p>
                    </div>
                    <hr></hr>
                    <div className="topSs">
                      <h2>John Doe</h2>
                      <p className="activeChip">ACTIVE</p>
                    </div>
                    <p className="email">contact@email.com</p>
                    <div className="editSkills">
                      <h4>Edit Skills</h4>
                    </div>
                    <div className="topSs">
                      <h2>Jane Doe</h2>
                      <p className="activeChip">ACTIVE</p>
                    </div>
                    <p className="email">contact@email.com</p>
                    <div className="editSkills">
                      <h4>Edit Skills</h4>
                    </div>
                  </div>
                  <div className="dotFillerCtr ss">{/* <DotFiller /> */}</div>
                  <div className="ssB">{/* <DotFiller /> */}</div>
                </div>
                <h3 id="showcase">
                  {!props.isIntern
                    ? "Register as a technical resource"
                    : "Register for an internship"}
                </h3>
                <p id="showcaseP">
                  {props.isIntern
                    ? "Register as an intern and get full access to edit your profile."
                    : "Register as a technical resource and get full access to edit your profile."}
                </p>
                <div className="stepsCtr">
                  <div className="dot active"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          ) : (
            <ErrorPage />
          )}
        </>
      )}
    </div>
  );
}

export default PartnerDevSignUp;
