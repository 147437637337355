import React, { useCallback, useEffect, useRef, useState } from "react";
import SingleContent from "../../../../globals/SpAssessement/SpAssessementBody/SingleContent";
import SpScrollableTab from "../../../../globals/SpScrollableTab";
import SingleTabHead from "../../../../globals/SpAssessement/SpAssessementHead/SingleHeadTab";
import prof from "../../../../images/profile2.png";
import { CircularProgress } from "@mui/material";
import { fetchQuestionsForUnit } from "../../../../hooks/useDevAssessments";

const DevAssessement = ({
  data,
  userRef,
  showScore = true,
  showRate = false,
  showDifficulty = true,
}) => {
  const [selectedHeadIndex, setSelectedHeadIndex] = useState(0);
  const [allUnitsData, setAllUnitsData] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [error, setError] = useState(null);

  const tabRef = useRef();

  // Prepare units data when `data` changes
  // Prepare units data when `data` changes
  useEffect(() => {
    if (data) {
      const units = data.reduce(
        (acc, item) => acc.concat(item?.unitsData || []),
        []
      );
      setAllUnitsData(units);
    }
  }, [data]);

  // Fetch questions data dynamically when a unit is selected or on initial load
  const loadQuestionsForUnit = useCallback(
    async (index) => {
      const selectedUnit = allUnitsData[index];
      const devID = data?.[0]?.devID;
      // console.log("selectedUnit", selectedUnit);
      // console.log("devID", devID);

      if (selectedUnit && !selectedUnit.questionsData) {
        setLoadingQuestions(true);
        setError(null);

        try {
          const updatedUnit = await fetchQuestionsForUnit({
            devID,
            unitRef: selectedUnit.ref,
          });

          setAllUnitsData((prev) => {
            const newUnits = [...prev];
            newUnits[index] = updatedUnit;
            return newUnits;
          });
        } catch (err) {
          setError("Failed to load questions.");
          console.error(err);
        } finally {
          setLoadingQuestions(false);
        }
      }
    },
    [data]
  );

  // Load questions for the initially selected unit
  useEffect(() => {
    if (allUnitsData.length > 0) {
      loadQuestionsForUnit(selectedHeadIndex);
    }
  }, [selectedHeadIndex, loadQuestionsForUnit]);

  // Handle unit selection change
  const handleUnitChange = (args) => {
    if (args.index !== selectedHeadIndex) {
      setSelectedHeadIndex(args.index);
      // loadQuestionsForUnit(args.index);
      tabRef?.current?.setSelectedIndex(0);
    }
  };

  // Format the score
  const formatScore = (score) =>
    score % 1 === 0 ? score.toString() : score.toFixed(1);
  //   const handleChangeTab = () => {
  //     // Change selected tab index to 3
  //   };

  // console.log("allUnitsData?.[selectedHeadIndex]?.questionsData", allUnitsData);

  return (
    <div className="account-leadData_switches__assess">
      <SpScrollableTab
        data={allUnitsData}
        onItemChange={handleUnitChange}
        renderItem={({ item, index, selectedIndex, setSelectedIndex }) => (
          <SingleTabHead
            key={item?.id}
            item={item}
            isActive={selectedIndex === index ? "1" : "0"}
            topicCount={index + 1}
            topicTotal={allUnitsData?.length}
            onClick={() => {
              setSelectedIndex(index);
              handleUnitChange({ index });
            }}
            title={item?.name}
            showScore={false}
            showRate={true}
            score={item?.score || "?"}
            // rate={item}
          />
        )}
      />

      {loadingQuestions ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <div style={{ padding: "20px", textAlign: "center", color: "red" }}>
          {error}
        </div>
      ) : allUnitsData?.[selectedHeadIndex]?.questionsData?.length > 0 ? (
        <SpScrollableTab
          style={{
            backgroundColor: "#f5f5f5",
            padding: "20px 0",
            border: "1px solid #dfdfdf",
            borderTop: "none",
          }}
          tabListStyle={{
            flex: 1,
            display: "flex",
            border: "none",
            gap: "20px",
          }}
          ref={tabRef}
          data={allUnitsData?.[selectedHeadIndex]?.questionsData
            ?.filter((e) => e?.isQuestion)
            ?.sort((a, b) => {
              if (a?.questionData?.difficulty < b?.questionData?.difficulty)
                return -1;
              if (a?.questionData?.difficulty > b?.questionData?.difficulty)
                return 1;
              return 0;
            })}
          renderItem={({ item, index, selectedIndex, setSelectedIndex }) => (
            <SingleContent
              key={item?.id}
              item={item}
              imgUrl={prof}
              question={item?.questionData?.question}
              answer={
                item?.submissionsData?.filter(
                  (el) => el?.author?.id === userRef?.id
                )?.[0]?.msg
              }
              isActive={selectedIndex === index ? "1" : "0"}
              autoPlay={false}
              // topicCount={item}
              // topicTotal={6}
              onClick={() => setSelectedIndex(index)}
              // rate={item}
              difficulty={item?.questionData?.difficulty}
              score={item?.score > 0 ? formatScore(item?.score * 5) : "?"}
              showScore={showScore}
              showDifficulty={showDifficulty}
            />
          )}
        />
      ) : (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <p>No questions available.</p>
        </div>
      )}
    </div>
  );
};

export default DevAssessement;
