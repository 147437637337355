import { ArrowBack } from "@mui/icons-material";
import { useState, useEffect } from "react";
import DomainsCaroussel from "../Developer/Carousels/Domains/DomainsCarousel";
import AddNew from "./components/AddNew";

export default function Subsectors(props) {
  const [selectedData, setSelectedData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const defaultStack = props?.domains[0] ?? {};

  const domId = "collect_edit_subdomain";

  const categoryId =
    props.categoriesId[selectedData.category] ??
    props.categoriesId[defaultStack.category];

  /**
   * takes the id of the input and the event3.0+
   * +
   * of the click then opens the file uploads
   * @param {event} e button event
   * @param {string} id input id
   */
  const handleFileUpload = (e, id) => {
    const uploadBtn = document.getElementById(id);
    uploadBtn.click();
  };
  const handleSelectedData = (selectedData) => {
    setSelectedData(selectedData);
  };

  useEffect(() => {
    if (!openDialog && !isEdit) {
      setSelectedData({});
    }
  }, [openDialog, isEdit]);

  const handleEditClick = () => {
    setIsEdit(true);
    setOpenDialog(true);
  };

  useEffect(() => {
    const fetchAssessmentNames = async () => {
      const data = selectedData ?? props?.domains[0] ?? {};
      // if (assessments?.length >= 1) return;
      if (data?.assessments && data.assessments.length > 0) {
        const fetchedAssessments = await Promise.all(
          data.assessments.map(async (assessment) => {
            // console.log("assessment", assessment);
            const doc = await (assessment?.ref ?? assessment?.source)?.get();
            return {
              ref: assessment?.ref,
              id: assessment?.ref?.id,
              rank: assessment?.rank,
              data: { ...doc.data() },
            }; // Assuming 'name' is the property to display
          })
        );
        setAssessments(fetchedAssessments);
      } else {
        // setAssessmentNames([]); // Reset if no assessments
        setAssessments([]);
      }
    };

    fetchAssessmentNames();
  }, [selectedData, props?.domains, props.selectedSector]);

  // console.log("openDialog", openDialog);

  // console.log("Subsectors props:", {
  //   domains: props.domains,
  //   selectedSector: props.selectedSector,
  //   subsectors: props.subSectors,
  // });

  return (
    <div
      className="colManagement_body-stacks collection"
      style={{ minHeight: "50vh" }}
    >
      <div style={{ marginBottom: "4rem" }}>
        <button
          className="spbtn spbtn-blackbkg"
          onClick={() => props.setViewSubs(false)}
        >
          <ArrowBack />
          Back
        </button>
      </div>
      {(openDialog || isEdit) && (
        <AddNew
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          open={openDialog || isEdit}
          onClose={() => {
            setOpenDialog(false);
            setIsEdit(false);
          }}
          techsCategories={props.domainCategories}
          firebase={props.firebase}
          handleFileUpload={handleFileUpload}
          setIsUpdated={setIsUpdated}
          collection="subSectors"
          selectedSector={props.selectedSector}
          isEdit={isEdit}
          categoryId={categoryId}
          setIsEdit={setIsEdit}
          isSub={true}
          defaultData={selectedData.name ? selectedData : defaultStack}
          searchData={props.searchData}
          setSearchData={props.setSearchData}
          showDropdown={props.showDropdown}
          setShowDropdown={props.setShowDropdown}
          searchAssessments={props.searchAssessments}
          selectedAssessmentNames={props.selectedAssessmentNames.stacks}
          setSelectedAssessmentNames={props.setSelectedAssessmentNames}
          assessments={assessments}
          setAssessments={setAssessments}
          refreshData={props.refreshData}
        />
      )}
      <div className="collection_header">
        <h3> {props.selectedSector?.name} SUBSECTORS</h3>
        <p>All Sprytelabs Industries.</p>
      </div>
      <div className="row collection_data">
        <div className="collection_data-grid">
          {props.domains?.length > 0 ? (
            <DomainsCaroussel
              showHiddenCard={false}
              domains={props.domains}
              firebase={props.firebase}
              domainExperiencesObject={props.stacksObbject}
              numOfDomToShow={12}
              setSelectedDomain={handleSelectedData}
              useExist={false}
              domId={props.domId}
              isSub={true}
              selectedSector={props.selectedSector}
            />
          ) : (
            <div>No subsectors found</div>
          )}
          <div>
            <button
              className="spbtn spbtn-sub"
              onClick={() => setOpenDialog(true)}
            >
              Add New Sub-Sector
            </button>
          </div>
        </div>
        {props.domains.length > 0 && (
          <div className="collection_data-information" id={`${domId}`}>
            <div className="collection_data-information__header row">
              <h3>Edit {selectedData?.name ?? defaultStack.name} Category</h3>
              <div className="spbtn">
                <button
                  id="stacklogoEditBtn"
                  className="spbtn-sub spbtn"
                  onClick={handleEditClick}
                >
                  Edit
                </button>
              </div>
            </div>

            <div className="collection_data-information__detail">
              <div className="collectDetail">
                <span className="collectDetail_title">name</span>
                <p>{selectedData?.name ?? defaultStack.name}</p>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">Description</span>
                <p>{selectedData?.description ?? defaultStack.description}</p>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">searchKeywords</span>
                <p>
                  {selectedData?.searchKeywords ?? defaultStack.searchKeywords}
                </p>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">Category</span>
                <span>{selectedData?.category ?? defaultStack.category}</span>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">Id</span>
                <span>{selectedData?.id ?? defaultStack.id}</span>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">Show</span>
                <p>
                  {selectedData?.show ?? defaultStack.show ? "true" : "false"}
                </p>
              </div>

              <div className="collectDetail">
                <span className="collectDetail_title">LogoStorage</span>
                <div>
                  <span>
                    {selectedData?.logoStorage ?? defaultStack.logoStorage}
                  </span>
                </div>
              </div>
              <div className="collectDetail">
                {assessments?.length > 0 && (
                  <>
                    <span className="collectDetail_title">Assessments</span>
                    <div>
                      <span>
                        {assessments
                          .map((assessment) => assessment?.data?.name)
                          .join(", ")}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
